.homeslider{
    width: 100%;
    height: 100vh;
    .glider-track{
        height: 100vh;
        .elementSlider{
            width: 100%;
            height: 100vh;
            background-size: cover !important;
            background-position:center !important;
            background-repeat: no-repeat !important;
            position:relative;
            .container{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 80px 0;
                box-sizing: border-box;
                h1{
                    color: white;
                    font-size: 60px;
                    text-align:center;
                    line-height: 100px;
                    font-weight: 200;
                }
            }
            a{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.glider-dots.dots-homeslider-notices{
    position:relative;
    margin-top: -50px;
    height: 50px;
    .glider-dot {
        display: block;
        cursor: pointer;
        border: 1px solid #ffcc00;
        border-radius: 999px;
        background: none;
        width: 12px;
        height: 12px;
        margin: 7px;
        &.active{
            background: #ffcc00;
        }
    }

}
@media(max-width: 1024px){
    .homeslider{
        height: auto;
        .glider-track{
            height: 600px;
            .elementSlider{
                .container{
                    h1{
                        font-size: 40px;
                    }
                }
            }
        }
        
    }
}
@media(max-width: 768px){
    .homeslider{
        height: auto;
        .glider-track{
            height: 430px;
            .elementSlider{
                background-size: cover !important;
                .container{
                    justify-content: center;
                    h1{
                        font-size: 20px;
                        line-height: 40px;
                    }
                }
            }
        }
        
    }
}
@media(max-width: 500px){
    .homeslider{
        height: auto;
        .glider-track{
            height: 430px;
            .elementSlider{
                background-size: cover !important;
                .container{
                    justify-content: center;
                    h1{
                        font-size: 20px;
                        line-height: 40px;
                    }
                }
            }
        }
    }
    .glider-dots.dots-homeslider-notices{
        position:relative;
        margin-top: -30px;
        height: 30px;
        .glider-dot {
            display: block;
            cursor: pointer;
            border: 1px solid #ffcc00;
            border-radius: 999px;
            background: none;
            width: 8px;
            height: 8px;
            margin: 7px;
            &.active{
                background: #ffcc00;
            }
        }

    }
}