html, body{
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}
.container{
    width: 1140px;
    margin: auto;
    height: 100%;
    box-sizing: border-box;
}
.d-flex{
    display: flex;
}
.d-flex-column{
    flex-direction: column;
}
.d-flex-row{
    flex-direction: row;
}
.d-flex-space-between{
    justify-content: space-between;
}
.d-flex-space-around{
    justify-content: space-around;
}
.d-flex-center{
    justify-content: center;
}
.d-align-center{
    align-items: center;
}
.text-center{
    text-align:center;
}
.text-right{
    text-align:right;
}
.text-left{
    text-align:left;
}
h3, h4{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 50px;
    text-transform: uppercase;
    font-weight: normal;
}

a{
    font-size: 18px;
    text-decoration: none;
}
form{
    .form-group{
        width: 100%;
        input[type="text"], input[type="email"]{
            font-family: 'century-gothic';
            padding: 8px 5px;
            width: 100%;
            box-sizing: border-box;
            border: none;
        }
        select{
            font-family: 'century-gothic';
            padding: 8px 5px;
            cursor: pointer;
            width: 100%;
            box-sizing: border-box;
            border: none;
        }
        label{
            font-family: 'century-gothic';
        }
        textarea{
            width: 100%;
            box-sizing: border-box;
            resize: none;
            height: 100px;
            border: none;
            font-family: 'century-gothic';
        }
        input[type="submit"], input.cancel{
            background: none;
            border: 2px solid white;
            color: white;
            font-family: 'century-gothic';
            padding: 10px;
            font-size: 20px;
            cursor: pointer;
        }
    }
    
} 
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 60; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(63, 63, 63, 0.9); /* Black w/ opacity */
  &.visible{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* Modal Content (image) */
.contain-modal {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    img{
        height: 800px;
        width: auto;
    }
    .label{
        color: white;
        font-size: 20px;
        padding: 10px 0;
        text-align:center;
    }
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content, #caption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
.alert {
    position: relative;
    padding: .50rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    display: none;
    font-size: 12px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    &.alert-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }
    &.alert-danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
}
#nav-responsive{
    display: none;
}
.invalid-feedback-input{
    font-size: 14px;
    color: red;
}
.range-fenêtre, .range-porte-d-entrée, .range-volet{
    #range-logo{
        background: #ffcc00 !important;
    }
    .more-hover{
        background: rgba(255,204,0,0.7) !important;
    }
}
.range-décoration-et-mobilier-d-extérieur{
    #range-logo{
        background: #967cab !important;
    }
    .more-hover{
        background: rgba(150,124,171,0.7) !important;
    }
}
.range-porte-de-garage{
    #range-logo{
        background: #f1592e !important;
    }
    .more-hover{
        background: rgba(241,89,46,0.7) !important;
    }
}
.range-pergola-carport{
    #range-logo{
        background: #4db848 !important;
    }
    .more-hover{
        background: rgba(77,184,77,0.7) !important;
    }
}
.range-clôture, .range-portail{
    #range-logo{
        background: #004689 !important;
    }
    .more-hover{
        background: rgba(0, 70, 137, 0.7) !important;
    }
}
@media (max-width: 1140px){
    .container{
        width: 1024px;
        padding: 0 10px;
    }
}
@media (max-width: 1024px){
    .container{
        width: 768px;
        padding: 0 10px;
    }
    h3, h4{
        font-size: 40px;
    }
}
@media (max-width: 768px){
    .container{
        width: 100%;
        padding: 0 10px;
    }
    #nav{
        display: none;
    }
    #nav-responsive{
        display: block;
    }
}
@media (max-width: 768px){
    .container{
        width: 100%;
    }
    #nav{
        display: none;
    }
    #nav-responsive{
        display: block;
    }
    h3, h4{
        font-size: 30px;
    }
    .modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 60; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgba(63, 63, 63, 0.9); /* Black w/ opacity */
        &.visible{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    /* Modal Content (image) */
    .contain-modal {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 100%;
        img{
            height: auto;
            width: 80%;
        }
        .label{
            color: white;
            font-size: 20px;
            padding: 10px 0;
            text-align:center;
        }
    }
}
@media (max-width: 500px){
    .container{
        width: 100%;
    }
    #nav{
        display: none;
    }
    #nav-responsive{
        display: block;
    }
    h3, h4{
        font-size: 30px;
    }
    .modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 60; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgba(63, 63, 63, 0.9); /* Black w/ opacity */
        &.visible{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    /* Modal Content (image) */
    .contain-modal {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 100%;
        img{
            height: auto;
            width: 80%;
        }
        .label{
            color: white;
            font-size: 20px;
            padding: 10px 0;
            text-align:center;
        }
    }
}

