#notices{
    .noticesHead{
        width: 100%;
        height: auto;
        img{
            width: 100%;
            display: block;
        }
    }
    .noticeCaption{
        position:relative;
        top: 0;
        height: auto;
        >div:first-child{
            position:absolute;
            top: 0;
            height: 100px;
            width: 100%;
            .left, .right{
                width: 50%;
                // background: #bdcad4;
                height: 100%;
            }
            .left{
                background: linear-gradient(
                    to left,
                    transparent 0%,
                    transparent 225px,
                    #667484 225px,
                    #667484 100%
                );
            }
            .right{
                background: linear-gradient(
                    to right,
                    transparent 0%,
                    transparent 225px,
                    #667484 225px,
                    #667484 100%
                );
            }
        }
        .container{

            #notice-title{
                width: 400px;
                height: 300px;
                background: #bdcad4;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                box-sizing: border-box;
                img{
                    width: 50%;
                }
                h1{
                    color: #667484;
                    font-weight: 500;
                    text-align:center;
                    line-height: 60px;
                    span{
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    .notices{
        padding: 40px 0;
        background: white;
        .content-notices{
            .glider-contain{
                .gliderNotices{
                    .glider-track{
                        .glide-notice{
                            .notice{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                padding: 50px 0;
                                row-gap: 60px;
                                background: white;
                                .header, .content{
                                    width: 1140px;
                                    display: flex;
                                    flex-direction: column;
                                    text-align:center;
                                }
                                .header{
                                    .name{
                                        color: #727f8e;
                                    }
                                    .date{
                                        color: #bdcad4;
                                        font-size: 14px;
                                    }
                                }
                                .content{
                                    span{
                                        color: #727f8e;
                                        line-height: 30px;
                                    }
                                }
                                &.pair{
                                    background: #edf1f4;
                                }
                            }   
                        }
                    }
                }
                .dots-number{
                    display: flex;
                    justify-content: center;
                    column-gap: 5px;
                    .numberPages{
                        padding: 3px 10px;
                        background: #edf1f4;
                        color: #667484;
                        cursor: pointer;
                        &.active, &:hover{
                            background: #97abbb;
                            color: #ffcc00;
                        }
                    }
                }
            }
        }
    }
    .formNotices{
        background: #ffcc00;
        padding: 60px 0;
        .container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h2{
                padding: 0;
                margin: 0;
                text-align:center;
                color: white;
                text-align:center;
                font-weight: normal;
                text-transform: uppercase;
                font-size: 2em;
                height: 20%;
                margin-bottom: 60px;
            }
            .notices_form{
                height: 80%;
                width: 80%;
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                .form-group{
                    input{
                        border: none;
                        padding: 10px;
                    }
                    textarea{
                        padding: 10px;
                    }
                    &.submit{
                        display: flex;
                        justify-content: center;
                        padding: 10px 0;
                        input{
                            font-size: 16px;
                            border: 1px solid white;
                            padding: 8px 30px;
                            cursor: pointer;
                            &:hover{
                                background: #97abbb;
                            }
                        }
                    }
                }
                .d-flex{
                    .form-group{
                        &:last-child{
                            justify-content: flex-end;
                        }
                        input{
                            width: 95%;
                        }
                    }
                    
                }
            }
        }
    }
}
@media (max-width: 768px){
    #notices{
        margin-top: 70px;
        .noticesHead{
            height: auto;
        }
        .noticeCaption{
            >div:first-child{
                display: none;
            }
            .container{
                width: 100%;
                padding: 0;
                #notice-title{
                    width: 100%;
                    img{
                        height: 150px;
                        width: auto;
                    }
                }
            }
        }
        .notices{
            padding: 40px 0;
            background: white;
            .content-notices{
                .glider-contain{
                    .gliderNotices{
                        .glider-track{
                            .glide-notice{
                                .notice{
                                    padding: 50px 10px;
                                    .header, .content{
                                        width: 100%;
                                    }
                                }   
                            }
                        }
                    }
                    .dots-number{
                        display: flex;
                        justify-content: center;
                        column-gap: 5px;
                        .numberPages{
                            padding: 3px 10px;
                            background: #edf1f4;
                            color: #667484;
                            cursor: pointer;
                            &.active, &:hover{
                                background: #97abbb;
                                color: #ffcc00;
                            }
                        }
                    }
                }
            }
        }
        .formNotices{
            .container{
                padding: 0 10px;
                .notices_form{
                    height: 80%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    .d-flex{
                        flex-direction: column;
                        .form-group{
                            input{
                                width: 100% !important;
                            }
                            &:first-child{
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .form-group{
                        input{
                            border: none;
                            padding: 10px;
                        }
                        textarea{
                            padding: 10px;
                        }
                        &.submit{
                            display: flex;
                            justify-content: center;
                            padding: 10px 0;
                            input{
                                font-size: 16px;
                                border: 1px solid white;
                                padding: 8px 30px;
                                cursor: pointer;
                                &:hover{
                                    background: #97abbb;
                                }
                            }
                        }
                    }
                    .d-flex{
                        .form-group{
                            &:last-child{
                                justify-content: flex-end;
                            }
                            input{
                                width: 95%;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}
@media (max-width: 500px){
    #notices{
        margin-top: 56px;
        .noticesHead{
            height: auto;
        }
        .noticeCaption{
            >div:first-child{
                display: none;
            }
            .container{
                width: 100%;
                padding: 0;
                #notice-title{
                    width: 100%;
                    img{
                        height: 150px;
                        width: auto;
                    }
                }
            }
        }
        .notices{
            padding: 40px 0;
            background: white;
            .content-notices{
                .glider-contain{
                    .gliderNotices{
                        .glider-track{
                            .glide-notice{
                                .notice{
                                    padding: 50px 10px;
                                    .header, .content{
                                        width: 100%;
                                    }
                                }   
                            }
                        }
                    }
                    .dots-number{
                        display: flex;
                        justify-content: center;
                        column-gap: 5px;
                        .numberPages{
                            padding: 3px 10px;
                            background: #edf1f4;
                            color: #667484;
                            cursor: pointer;
                            &.active, &:hover{
                                background: #97abbb;
                                color: #ffcc00;
                            }
                        }
                    }
                }
            }
        }
        .formNotices{
            .container{
                padding: 0 10px;
                .notices_form{
                    height: 80%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    .d-flex{
                        flex-direction: column;
                        .form-group{
                            input{
                                width: 100% !important;
                            }
                            &:first-child{
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .form-group{
                        input{
                            border: none;
                            padding: 10px;
                        }
                        textarea{
                            padding: 10px;
                        }
                        &.submit{
                            display: flex;
                            justify-content: center;
                            padding: 10px 0;
                            input{
                                font-size: 16px;
                                border: 1px solid white;
                                padding: 8px 30px;
                                cursor: pointer;
                                &:hover{
                                    background: #97abbb;
                                }
                            }
                        }
                    }
                    .d-flex{
                        .form-group{
                            &:last-child{
                                justify-content: flex-end;
                            }
                            input{
                                width: 95%;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}