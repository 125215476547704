#homeAbout{
    height: auto;
    padding: 60px 0;
    .container{
        gap: 30px;
        h3{
            color: #004689;
            text-align:center;
        }
        div.content-text{
            color: #697583;
            font-size: 22px;
            font-weight: normal;
            display: flex;
            flex-direction: column;
            p{
                line-height: 35px;
                text-align:center;
            }
        }
        div.more{
            a{
                color: #004689;
                border: 1px solid #004689;
                padding: 5px 15px;
                &:hover{
                    color: #ffcc00;
                    border: none;
                    background: #004689;
                }
            }
        }
    }
}
#homeContact{
    width: 100%;
    background: linear-gradient(
        to right,
        #004689 0%,
        #004689 50%,
        #ffcc00 50%,
        #ffcc00 100%
    );
    height: auto;
    min-height: 700px;
    padding: 60px 0;
    .container{
        h4{
            color: #ffcc00;
            text-align:center;
            height: 20%;
        }
        > div{
            width: 50%;
            height: 700px;
            &.contactInfos{
                background: url('/public/build/theme/logo-homecontact.png');
                background-size: 450px auto;
                background-position:center;
                background-repeat: no-repeat;
                div.infos{
                    height: 80%;
                    align-items: center;
                    span{
                        font-size: 30px;
                        color: white;
                        text-align:center;
                        line-height: 50px;
                        &.point{
                            content: '';
                            width: 10px;
                            height: 10px;
                            background-color: white;
                            border-radius: 50%;
                        }
                    }
                }
            }
            &.contactForm{
                display: flex;
                justify-content: flex-end;
                form{
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    align-items: flex-end;
                    justify-content: space-between;
                    .sel-type{
                        display: flex;
                        width: 100%;
                        > div{
                            width: 50%;
                            display: flex;
                            flex-direction: row-reverse;
                            justify-content: flex-start;
                            &:first-child{
                                justify-content: flex-end;
                            }
                        }
                    }
                    .subscribe{
                        label{
                            font-size: 12px;
                        }
                    }
                    .submit{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        input:hover{
                            background: #004689;
                            color: #ffcc00;
                        }
                    }
                    .text{
                        span, a{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
#homeNews{
    width: 100%;
    height: 600px;
    display: flex;
    >div {
        width: 50%;
        height: 600px;
        display: block;
        padding: 60px 0;
        box-sizing: border-box;
        &.left{
            background: url('/public/build/theme/background-news.jpg');
            background-size: cover;
            background-position:center;
        }
        &.right{
            background: url('/public/build/theme/background-news-right.jpg');
            background-size: cover;
            .content-right{
                width: 570px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                h4{
                    color: white;
                    text-align:center;
                    height: 20%;
                }
                .glider-contain{
                    height: 80%;
                    .glider-news{
                        height: 380px;
                    }
                    .glider-slide{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;  
                        span{
                            height: 380px;
                            img{
                                height: 100%;
                                width: auto;
                            }
                        } 
                        
                    }
                    .glider-dots{
                        margin-top: 20px;
                        .glider-dot{
                            border: 1px solid #ffcc00;
                            background: none;
                            &.active{
                                background: #ffcc00;
                            }
                        }
                    }
                }
                
            }
        }
    }
}

@media (max-width: 1024px){
    #homeAbout{
        height: auto;
        .container{
            div.content-text{
                font-size: 20px;
            }
        }
    }
    #homeNews{
        >div{
            &.right{
                width: 50%;
                .content-right{
                    width: 384px;
                }
            }
        }
    }
    #homeServices{
        .container{
            .content-pictos{
                .bloc{
                    span{
                        font-size: 18px;
                        &.more{
                            display: none;
                            font-size: 12px;
                            text-align:center;
                        }
                    }
                }
            }
        }
        
    }
    #homeContact{
        .container{
            > div{
                box-sizing: border-box;
                &.contactInfos{
                    padding-right: 10px;
                    background-size: 350px auto;
                    div.infos{
                        span{
                            font-size: 25px;
                        }
                    }
                }
                &.contactForm{
                    display: flex;
                    justify-content: flex-end;
                    padding-left: 10px;
                    form{
                        width: 100%;
                        .sel-type{
                           label{
                            font-size: 15px;
                           }
                        }
                        .subscribe{
                            label{
                                font-size: 14px;
                            }
                        }
                    }
                    
                }
            }
        }
    }
    #homePlan{
        .container{
            &#containerTop{
                .infos{
                    padding-right: 10px;
                    box-sizing: border-box;
                    >div{
                        display: flex;
                        flex-direction: column;
                        div.img{
                            width: 100%;
                            img{
                                height: 70px;
                            }
                        }
                        div.text{
                            span.title{
                                text-align:center;
                            }
                            span.text{
                                text-align:center;
                            }
                        }
                    }
                }
            }
            &#containerBottom{
                height: 150px;
                display: flex;
                justify-content: flex-end;
                z-index: 1;

                #social{
                    width: 50%;
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    row-gap: 10px;
                    >span{
                        color: #a7bac9;
                        font-size: 25px;
                    }
                    #linkSocial{
                        height: 50px;
                        display: flex;
                        justify-content: flex-end;
                        gap: 20px;
                        a{
                            width: 44px;
                            height: 44px;
                            display: block;
                            &.social-fb{
                                background: url('../theme/fb-footer.png');
                                background-repeat: no-repeat;
                                background-size: cover;
                                &:hover{
                                    background: url('../theme/fb-hover.png');
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                }
                            }
                            &.social-insta{
                                background: url('../theme/insta-footer.png');
                                background-repeat: no-repeat;
                                background-size: cover;
                                &:hover{
                                    background: url('../theme/insta-hover.png');
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                }
                            }
                            &.social-linkedin{
                                background: url('../theme/ln-footer.png');
                                background-repeat: no-repeat;
                                background-size: cover;
                                &:hover{
                                    background: url('../theme/linkedin-hover.png');
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                }
                            }
                            &.social-tiktok{
                                background: url('../theme/tiktok-footer.png');
                                background-repeat: no-repeat;
                                background-size: cover;
                                &:hover{
                                    background: url('../theme/tiktok-hover.png');
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
}

@media (max-width: 768px){
    #home{
        margin-top: 70px;
    }
    #homeAbout {
        height: auto;
        margin: 40px 10px;
    }
    #homePlan{
        .container{
            &#containerTop{
                padding: 60px 10px;
            }
        }
    }
    #homeNews{
        >div{
            &.right{
                padding: 0 10px;
                .content-right{
                    width: 100%;
                }
            }
        }
    } 
}
@media (max-width: 768px){
    #homeAbout {
        height: auto;
        margin: 40px 10px;
    }
    #homePlan{
        .container{
            &#containerTop{
                padding: 60px 10px;
            }
        }
    }
    #homeNews{
        >div{
            &.right{
                padding: 0 10px;
                .content-right{
                    width: 100%;
                }
            }
        }
    } 
}
@media (max-width: 500px){
    #home{
        margin-top: 56px;
    }
    #homeAbout{
        height: auto;
        .container{
            div.content-text{
                font-size: 20px;
            }
        }
    }
    #homeContact{
        background:  none;
        padding: 0;
        .container{
            flex-direction: column;
            padding: 0;
            h4{
                height: 10%;
            }
            > div{
                width: 100%;
                &.contactInfos{
                    background: #004689;
                    padding: 40px 10px;
                    height: auto;
                    div.infos{
                        row-gap: 10px;
                        span{
                            font-size: 20px;
                            line-height: 30px;
                            &.point{
                                display: none;
                            }
                        }
                    }
                }
                &.contactForm{
                    background: #ffcc00;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 40px 10px;
                    form{
                        width: 100%;
                        .sel-type{
                           label{
                            font-size: 13px;
                           }
                        }
                        .subscribe{
                            label{
                                font-size: 12px;
                            }
                        }
                        .text{
                            span{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    #homeNews{
        height: auto;
        >div{
            &.left{
                display: none;
            }
            &.right{
                width: 100%;
                padding: 40px 10px;
                .content-right{
                    height: auto;
                }
            }
        }
    }
    #homeNotices{
        height: auto;
        padding: 40px 10px;
        padding-bottom: 10px;
        box-sizing: border-box;
        .container{
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            padding: 0;
            h4{
                height: 10%;
            }
            .content-notices{
                .glider-contain{
                    .gliderNotices{
                        .glide-notice{
                            row-gap: 10px;
                            .header{
                                span{
                                    &.name{
                                        font-size: 20px;
                                    }
                                    &.date{
                                        font-size: 14px;
                                    }
                                    font-size: 16px;
                                }
                            }
                            .content{
                                span{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    #notices-link{
                        display: flex;
                        justify-content: center;
                        gap: 15px;
                        a, span{
                            color: #bdcad4;
                            cursor: pointer;
                            &:hover{
                                color: #777;
                            }
                            font-size: 16px;
                        }
                        span{
                            display: none;
                        }
                    }
                    .dots-notices{
                        margin-top: 0;
                        .glider-dot{
                            border: 1px solid #a7bac9;
                            background: none;
                            &.active{
                                background: #a7bac9;
                            }
                        }
                    }
                }
            }
            
            
        }
    }
    #homePartners{
        width: 100%;
        height: 200px;
        padding: 60px 0;
        .container{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            h4{
                color: #dce3e9;
                text-align:center;
                height: 20%;
            }
            .content-partners{
                width: 96%;
                .glider-contain{
                    .gliderPartners{
                        &::-webkit-scrollbar{
                            display: none;
                        }
                        .glide-partners{
                            display: flex;
                            justify-content: center;
                            position:relative;
                            > a{
                                position:absolute;
                                top: 0;
                                right: 0;
                                width: 100%;
                                height: 100%;
                            }
                            img{
                                width: auto;
                                max-height: 86px;
                            }
                        }
                    
                    }
                    .glider-next, .glider-prev{
                        font-size: 16px;
                        padding: 5px 7px;
                        border: 1px solid black;
                        border-radius: 15px;
                    }
                    .glider-next{
                        right: 0;
                    }
                    .glider-prev{
                        left: 0;
                    }
                    
                }
                
                
            }
        }
    }
    #homeServices{
        min-height: auto;
        padding: 40px 0;
        .container{
            .content-pictos{
                flex-wrap: wrap;
                row-gap: 30px;
                .bloc{
                    width: 50%;
                    cursor: pointer;
                    row-gap: 10px;
                    .picto{
                        width: 100%;
                        height: 90px;
                        &#picto-accueil{
                            background-size: 80px 76px;
                        }
                        &#picto-suivi{
                            background-size: 80px 72px;
                        }
                        &#picto-team{
                            background-size: 80px 63px;
                        }
                        &#picto-sav{
                            background-size: 80px 77px;
                        }
                    }
                    span{
                        font-size: 18px;
                        &.more{
                            display: none;
                            font-size: 14px;
                            text-align:center;
                        }
                    }
                    &.active{
                        #picto-accueil{
                            background-size: 90px;
                        }
                        #picto-suivi{
                            background-size: 90px;
                        }
                        #picto-team{
                            background-size: 90px;
                        }
                        #picto-sav{
                            background-size: 90px;
                        }
                        span{
                            &.more{
                                display: block;
                                color: white !important;
                            }
                        }
                        &#accueil{
                            > span{
                                color: #005a9c;
                            }
                        }
                        &#suivi{
                            > span{
                                color: #e67929;
                            }
                        }
                        &#team{
                            > span{
                                color: #34aa46;
                            }
                        }
                        &#sav{
                            > span{
                                color: #e9a016;
                            }
                        }
                    }
                }
            }
        }
    }
    #homePlan{
        height: auto;
        padding: 40px 0;
        row-gap: 20px;
        display: flex;
        flex-direction: column;
        .footerBlock{
            display: none;
        }
        .container{
            display: flex;
            flex-direction: column;
            
            &#containerTop{
                height: auto;
                padding: 0 10px;
                row-gap: 20px;
                .infos{
                    width: 100%;
                    row-gap: 20px;
                    >div{
                        display: flex;
                        flex-direction: row;
                        .img{
                            width: 30% !important;
                        }
                        .text{
                            width: 70% !important;
                            span{
                                text-align:center;
                                width: 100% !important;
                                &.text{
                                    line-height: 20px !important;
                                    font-family: 'century-gothic';
                                }
                            }
                        }
                    }
                }
                #plan{
                    height: 250px;
                    width: 100%;
                }
            }
            &#containerBottom{
                height: auto;
                #social{
                    width: 100%;
                    align-items: center;
                }
            }
        }
    }
}