#contact{
    #formContact{
        width: 100%;
        background: url('/public/build/theme/background-contact.jpg');
        background-size: 100% 100%;
        background-position:center;
        background-repeat: no-repeat;
        padding-top: 150px;
        .container{
            h1{
                color: white;
                font-weight: normal;
                text-align: center;
                text-transform: uppercase;
                font-size: 60px;
            }
            .contactInfos{
                display: flex;
                justify-content: space-between;
                padding: 60px 0;
                >div{
                    display: flex;
                    column-gap: 15px;
                    width: 30%;
                    justify-content: center;
                    >div{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        span{
                            color: white;
                            font-weight: lighter;
                            &.title{
                                color: #ffcc00;
                                font-size: 20px;
                                height: 30%;
                            }
                            &.text{
                                height: 70%;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .contactText{
                padding: 60px 0;
                display: flex;
                justify-content: center;
                span{
                    text-align:center;
                    color: white;
                    line-height: 40px;
                    font-size: 25px;
                }
            }
            #form{ 
                padding: 60px 0;
                form{
                    display: flex;
                    flex-direction: column;
                    row-gap: 25px;
                    div:not(.alert){
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-wrap: wrap ;
                        row-gap: 25px;
                        input{
                            width: 48%;
                            border: none;
                            font-family: 'Century-Gothic';
                            color: #667484;
                            font-size: 18px;
                            padding: 10px 0 10px 10px;
                        }
                        textarea{
                            width: 100%;
                            height: 300px;
                            resize: none;
                            border: none;
                            font-family: 'Century-Gothic';
                            color: #667484;
                            font-size: 18px;
                            padding: 10px;
                        }
                        &.submit{
                            display: flex;
                            justify-content: center;
                            .valid{
                                border: 1px solid white;
                                background: none;
                                color: white;
                                cursor: pointer;
                                width: 25%;
                                &:hover{
                                    background: #ffcc00;
                                    color: white;
                                    border: 1px solid white;
                                }
                            }
                        }
                        &.checkboxField{
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 10px;
                            input{
                                width: 2%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                            }
                            label{
                                font-family: 'Century-Gothic';
                                color: #333639;
                                font-size: 15px;
                                width: 96%;
                                a{
                                    text-transform: none;
                                    font-size: 15px;
                                    color: #004689;
                                }
                            }
                        }
                    }
                }
            }
        }
        #social{
            height: 65px;
            width: 100%;
            display: block;
            background: #667484;
            .container{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 40px;
                >span{
                    color: #a7bac9;
                    font-size: 30px;
                }
                a.social{
                    width: 44px;
                    height: 43px;
                    display: block;
                    &.social-fb{
                        background: url('../theme/fb.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/fb-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                    &.social-insta{
                        background: url('../theme/insta.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/insta-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                    &.social-linkedin{
                        background: url('../theme/linkedin.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/linkedin-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                    &.social-tiktok{
                        background: url('../theme/tiktok.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/tiktok-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
        #contactMap{
            width: 100%;
            height: 800px;
            z-index: 0;
        }
    }
}
@media (max-width: 1024px){
    #contact{
        #formContact{
            .container{
                .contactInfos{
                    >div{
                        flex-direction: column;
                        align-items: center;
                        img{
                            height: 105px;
                            width: auto;
                        }
                        >div{
                            margin-top: 10px;
                            span{
                                &.title{
                                    text-align:center;
                                }
                                &.text{
                                    text-align:center;
                                }
                            }
                        }
                    }
                }
                .contactText{
                    span{
                        font-size: 20px;
                    }
                }
                #form{
                    form{
                        div{
                            &.checkboxField{
                                input{
                                    width: 2%;
                                }
                                label{
                                    width: 95%;
                                }
                            }
                            &.submit{
                                display: flex;
                                justify-content: center;
                                .valid{
                                    border: 1px solid white;
                                    background: none;
                                    color: white;
                                    cursor: pointer;
                                    width: 40%;
                                    &:hover{
                                        background: #004689;
                                        color: white;
                                        border: 1px solid #004689;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px){
    #contact{
        margin-top: 56px;
        #formContact{
            .container{
                padding: 20px 0;
                .contactInfos{
                    flex-direction: column;
                    align-items: center;
                    row-gap: 50px;
                    >div{
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-around;
                        width: 60%;
                        >div{
                            width: 50%;
                            margin-top: 10px;
                            &.img{
                                display: flex;
                                justify-content: center;
                                width: auto;
                                min-width: 105px;
                                img{
                                    height: 105px;
                                    width: auto;
                                }
                            }
                            span{
                                &.title{
                                    text-align:left;
                                }
                                &.text{
                                    text-align:left;
                                }
                            }
                        }
                        &#telephone{
                            .img{
                                display: flex;
                                justify-content: center;
                                flex-direction: row;
                                img{
                                    width: 78px;
                                }
                            }
                        }
                        &#nous-trouver{
                            .img{
                                display: flex;
                                justify-content: center;
                                flex-direction: row;
                                img{
                                    width: 64px;
                                }
                            }
                        }
                    }
                }
                .contactText{
                    span{
                        font-size: 20px;
                    }
                }
                #form{
                    padding: 60px 10%;
                    form{
                        div{
                            &.checkboxField{
                                input{
                                    width: 2%;
                                }
                                label{
                                    width: 95%;
                                }
                            }
                            &.textfields{
                                input{
                                    width: 100%;
                                }
                            }
                            &.submit{
                                display: flex;
                                justify-content: center;
                                .valid{
                                    border: 1px solid white;
                                    background: none;
                                    color: white;
                                    cursor: pointer;
                                    width: 40%;
                                    &:hover{
                                        background: #004689;
                                        color: white;
                                        border: 1px solid #004689;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #social{
                height: auto;
                width: 100%;
                display: block;
                background: #667484;
                .container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 40px;
                    >span{
                        color: #a7bac9;
                        font-size: 30px;
                    }
                    a.social{
                        width: 44px;
                        height: 43px;
                        display: block;
                        &.social-fb{
                            background: url('../theme/fb.png');
                            background-repeat: no-repeat;
                            &:hover{
                                background: url('../theme/fb-hover.png');
                                background-repeat: no-repeat;
                            }
                        }
                        &.social-insta{
                            background: url('../theme/insta.png');
                            background-repeat: no-repeat;
                            &:hover{
                                background: url('../theme/insta-hover.png');
                                background-repeat: no-repeat;
                            }
                        }
                        &.social-linkedin{
                            background: url('../theme/linkedin.png');
                            background-repeat: no-repeat;
                            &:hover{
                                background: url('../theme/linkedin-hover.png');
                                background-repeat: no-repeat;
                            }
                        }
                        &.social-tiktok{
                            background: url('../theme/tiktok.png');
                            background-repeat: no-repeat;
                            &:hover{
                                background: url('../theme/tiktok-hover.png');
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 500px){
    #contact{
        margin-top: 56px;
        #formContact{
            padding-top: 60px;
            .container{
                padding: 20px 10px;
                h1{
                    font-size: 40px;
                }
                .contactInfos{
                    >div{
                        width: 90%;
                        >div{
                            width: 59%;
                            margin-top: 10px;
                            &.img{
                                display: flex;
                                justify-content: center;
                                width: auto;
                                min-width: 105px;
                                img{
                                    height: 105px;
                                    width: auto;
                                }
                            }
                            span{
                                &.title{
                                    text-align:left;
                                }
                                &.text{
                                    text-align:left;
                                }
                            }
                        }
                        &#telephone{
                            .img{
                                display: flex;
                                justify-content: center;
                                flex-direction: row;
                                img{
                                    width: 78px;
                                }
                            }
                        }
                        &#nous-trouver{
                            .img{
                                display: flex;
                                justify-content: center;
                                flex-direction: row;
                                img{
                                    width: 64px;
                                }
                            }
                        }
                    }
                }
                .contactText{
                    span{
                        font-size: 20px;
                    }
                }
                #form{
                    padding: 0;
                    form{
                        div{
                            &.checkboxField{
                                input{
                                    width: 4%;
                                }
                                label{
                                    width: 91%;
                                }
                            }
                            &.submit{
                                .valid{
                                    width: 100%;
                                    &:hover{
                                        background: #004689;
                                        color: white;
                                        border: 1px solid #004689;
                                    }
                                }
                            }
                        }
                        div:not(.alert){
                            &.submit{
                                .valid{
                                    width: 100%;
                                }
                            }
                            &.checkboxField{
                                input{
                                    width: 4%;
                                }
                                label{
                                    width: 91%;
                                }
                            }
                        }
                    }
                }
            }
            #social{
                height: auto;
                .container{
                    gap: 40px;
                    row-gap: 5px;
                    flex-wrap: wrap;
                    >span{
                        color: #a7bac9;
                        font-size: 30px;
                        width: 100%;
                        text-align:center;
                    }
                    a.social{
                        width: 44px;
                        height: 43px;
                        display: block;
                        &.social-fb{
                            background: url('../theme/fb.png');
                            background-repeat: no-repeat;
                            &:hover{
                                background: url('../theme/fb-hover.png');
                                background-repeat: no-repeat;
                            }
                        }
                        &.social-insta{
                            background: url('../theme/insta.png');
                            background-repeat: no-repeat;
                            &:hover{
                                background: url('../theme/insta-hover.png');
                                background-repeat: no-repeat;
                            }
                        }
                        &.social-linkedin{
                            background: url('../theme/linkedin.png');
                            background-repeat: no-repeat;
                            &:hover{
                                background: url('../theme/linkedin-hover.png');
                                background-repeat: no-repeat;
                            }
                        }
                        &.social-tiktok{
                            background: url('../theme/tiktok.png');
                            background-repeat: no-repeat;
                            &:hover{
                                background: url('../theme/tiktok-hover.png');
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
            #contactMap{
                width: 100%;
                height: 400px;
                z-index: 0;
            }
        }
    }
}