.footerPlan{
    width: 100%;
    height: 650px;
    background: url('/public/build/theme/background-plan.jpg');
    background-size: cover;
    position: relative;
    .footerBlock{
        width: 100%;
        height: 70px;
        width: 100%;
        height: 100px;
        transform-origin: 0% 100%;
        transform: skewX(0deg) skewY(-2deg);
        background: #667484;
        position: absolute;
        bottom: 0;
    }
    .container{
        display: flex;
        &#containerTop{
            height: 500px;
            padding: 60px 0;
            .infos{
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                >div{
                    display: flex;
                    div.img{
                        width: 30%;
                        text-align:center;
                    }
                    div.text{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        span.title{
                            color: #ffcc00;
                            font-size: 20px;
                        }
                        span.text{
                            color: white;
                            line-height: 25px;
                            font-size: 16px;
                        }
                    }
                }
            }
            #plan{
                width: 60%;
                z-index: 40;
            }
        }
        &#containerBottom{
            height: 150px;
            display: flex;
            justify-content: flex-end;
            z-index: 1;

            #social{
                width: 50%;
                z-index: 5;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                row-gap: 10px;
                >span{
                    color: #a7bac9;
                    font-size: 25px;
                }
                #linkSocial{
                    height: 50px;
                    display: flex;
                    justify-content: flex-end;
                    gap: 20px;
                    a{
                        width: 44px;
                        height: 44px;
                        display: block;
                        &.social-fb{
                            background: url('/public/build/theme/fb-footer.png');
                            background-repeat: no-repeat;
                            background-size: cover;
                            &:hover{
                                background: url('/public/build/theme/fb-hover.png');
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                        }
                        &.social-insta{
                            background: url('/public/build/theme/insta-footer.png');
                            background-repeat: no-repeat;
                            background-size: cover;
                            &:hover{
                                background: url('/public/build/theme/insta-hover.png');
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                        }
                        &.social-linkedin{
                            background: url('/public/build/theme/ln-footer.png');
                            background-repeat: no-repeat;
                            background-size: cover;
                            &:hover{
                                background: url('/public/build/theme/linkedin-hover.png');
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                        }
                        &.social-tiktok{
                            background: url('/public/build/theme/tiktok-footer.png');
                            background-repeat: no-repeat;
                            background-size: cover;
                            &:hover{
                                background: url('/public/build/theme/tiktok-hover.png');
                                background-repeat: no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                }
            }
        }
        
    }
}
@media (max-width: 1024px){
    .footerPlan{
        .container{
            &#containerTop{
                .infos{
                    >div{
                        div.img{
                            img{
                                width: 100%;
                            }
                        }
                        div.text{
                            justify-content: center;
                            margin-left: 20px;
                            span.title{
                                font-size: 18px;
                            }
                            span.text{
                                font-size: 14px;
                            }
                        }
                    }
                }
                #plan{
                    width: 60%;
                    z-index: 40;
                }
            }
        }
    }
}
@media (max-width: 768px){
    .footerPlan{
        .container{
            &#containerTop{
                padding: 60px 10px;
            }
        }
    }
}
@media (max-width: 500px){
    .footerPlan{
        height: auto;
        .container{
            display: flex;
            flex-direction: column;
            &#containerTop{
                height: 500px;
                padding: 30px 0;
                .infos{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    row-gap: 20px;
                    margin-bottom: 30px;
                    >div{
                        width: 250px;
                        display: flex;
                        justify-content: center;
                        div.img{
                            width: 100px;
                            text-align:center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                height: 80px;
                                width: auto;
                            }
                        }
                        div.text{
                            width: 150px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            span.title{
                                color: #ffcc00;
                                font-size: 15px;
                            }
                            span.text{
                                color: white;
                                line-height: 25px;
                                font-size: 12px;
                            }
                        }
                    }
                }
                #plan{
                    width: 100%;
                    height: 300px;
                    z-index: 40;
                }
            }
            &#containerBottom{
                padding-top: 30px;
                background: #667484;
                height: auto;
                #social{
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                }
            }   

        }
        .footerBlock{
            display: none;
        }
    }
}