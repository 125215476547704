.services{
    width: 100%;
    background: url('/public/build/theme/background-services.jpg');
    background-size: cover;
    background-position: center;
    height: auto;
    min-height: 408px;
    padding: 60px 0;
    .container{
        display: flex;
        flex-direction: column;
        gap: 60px;
        h3{
            color: white;
            text-align:center;
        }
        .content-pictos{
            .bloc{
                opacity: 0;
                -webkit-transition: all 1s ease-in-out;
                -moz-transition: all 1s ease-in-out;
                -ms-transition: all 1s ease-in-out;
                -o-transition: all 1s ease-in-out;
                transition: all 1s ease-in-out;
                &.visible{
                    opacity: 1;
                }
                width: 25%;
                cursor: pointer;
                row-gap: 20px;
                .picto{
                    width: 100%;
                    height: 117px;
                    &#picto-accueil{
                        background: url('/public/build/theme/picto-service-accueil.png');
                        background-size: 88px 84px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    &#picto-suivi{
                        background: url('/public/build/theme/picto-service-suivi.png');
                        background-size: 84px 76px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    &#picto-team{
                        background: url('/public/build/theme/picto-service-team.png');
                        background-size: 93px 70px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    &#picto-sav{
                        background: url('/public/build/theme/picto-service-sav.png');
                        background-size: 88px 85px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
                span{
                    color: white;
                    font-size: 20px;
                    text-align:center;
                    &.more{
                        display: none;
                        font-size: 16px;
                        text-align:center;
                    }
                }
                &:hover{
                    #picto-accueil{
                        background: url('/public/build/theme/picto-service-accueil-hover.png');
                        background-size: 117px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    #picto-suivi{
                        background: url('/public/build/theme/picto-service-suivi-hover.png');
                        background-size: 117px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    #picto-team{
                        background: url('/public/build/theme/picto-service-team-hover.png');
                        background-size: 117px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    #picto-sav{
                        background: url('/public/build/theme/picto-service-sav-hover.png');
                        background-size: 117px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    span{
                        &.more{
                            display: block;
                            color: white !important;
                        }
                    }
                    &#accueil{
                        > span{
                            color: #005a9c;
                        }
                    }
                    &#suivi{
                        > span{
                            color: #e67929;
                        }
                    }
                    &#team{
                        > span{
                            color: #34aa46;
                        }
                    }
                    &#sav{
                        > span{
                            color: #b18815;
                        }
                    }
                }
            }
        }
    }
    
}
@media (max-width: 500px){
    .services{
        .container{
            .content-pictos{
                flex-wrap: wrap;
                .bloc{
                    width: 50%;
                    .picto{
                        width: 100%;
                        height: 100px;
                        &#picto-accueil{
                            background: url('/public/build/theme/picto-service-accueil.png');
                            background-size: 80px 76px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        &#picto-suivi{
                            background: url('/public/build/theme/picto-service-suivi.png');
                            background-size: 80px 72px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        &#picto-team{
                            background: url('/public/build/theme/picto-service-team.png');
                            background-size: 89px 66px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        &#picto-sav{
                            background: url('/public/build/theme/picto-service-sav.png');
                            background-size: 84px 81px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }
                    span{
                        color: white;
                        font-size: 20px;
                        text-align:center;
                        &.more{
                            display: none;
                            font-size: 16px;
                            text-align:center;
                        }
                    }
                    &:hover{
                        #picto-accueil{
                            background: url('/public/build/theme/picto-service-accueil-hover.png');
                            background-size: 100px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        #picto-suivi{
                            background: url('/public/build/theme/picto-service-suivi-hover.png');
                            background-size: 100px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        #picto-team{
                            background: url('/public/build/theme/picto-service-team-hover.png');
                            background-size: 100px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        #picto-sav{
                            background: url('/public/build/theme/picto-service-sav-hover.png');
                            background-size: 100px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        span{
                            &.more{
                                display: block;
                                color: white !important;
                            }
                        }
                        &#accueil{
                            > span{
                                color: #005a9c;
                            }
                        }
                        &#suivi{
                            > span{
                                color: #e67929;
                            }
                        }
                        &#team{
                            > span{
                                color: #34aa46;
                            }
                        }
                        &#sav{
                            > span{
                                color: #b18815;
                            }
                        }
                    }
                }
            }
        }
    }
}