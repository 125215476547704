.homeLink{
    height: 100%;
    position: relative;
    &.homelink-bloc-link{
        background: #ffcc00;
        a.link{
            position:absolute;
            height: 100%;
            width: 100%;
        }
    }
    &.homelink-bloc-link.homelink-media-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        cursor: pointer;
        width: 100%;
        span{
            text-align:center;
            color: #004689;
            line-height: 40px;
            font-size: 25px;
            &.more{
                background: url('/public/build/theme/arrow-homeLink-off.png');
                background-size: 60px;
                width: 60px;
                height: 60px;
                display: block;
            }
        }
        &:hover{
            span{
                color: white;
                &.more{
                    background: url('/public/build/theme/arrow-homeLink-hover.png');
                    background-size: 60px;
                }
            }
        }
    }
    &.homelink-bloc-text.homelink-media-text{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        background: #004689;
        width: 100%;
        span{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            text-align:center;
            color: white;
            line-height: 30px;
            font-size: 20px;
            strong{
                font-weight: 200;
                color: #ffcc00;
                font-size: 25px;
            }
        }
    }
}
@media (max-width: 1024px){
    .homeLink{
        &.homelink-bloc-link.homelink-media-text{
            span{
                font-size: 20px;
            }
        }
        &.homelink-bloc-text.homelink-media-text{
            span{
                font-size: 18px;
                strong{
                    font-size: 20ppx;
                }
            }
        }
    }
}
@media (max-width: 768px){
    .homeLink{
        &.homelink-bloc-link.homelink-media-text{
            span{
                font-size: 16px;
                &.more{
                    background: url('/public/build/theme/arrow-homeLink-off.png');
                    background-size: 40px;
                    width: 40px;
                    height: 40px;
                    display: block;
                }
            }
            &:hover{
                span{
                    &.more{
                        background-size: 40px;
                    }
                }
            }
        }
        &.homelink-bloc-text.homelink-media-text{
            span{
                font-size: 16px;
                strong{
                    font-size: 20px;
                }
            }
        }
    }
}
@media (max-width: 500px){
    .homeLink{
        &.homelink-bloc-link.homelink-media-text{
            span{
                font-size: 16px;
                &.more{
                    background: url('/public/build/theme/arrow-homeLink-off.png');
                    background-size: 40px;
                    width: 40px;
                    height: 40px;
                    display: block;
                }
            }
            &:hover{
                span{
                    &.more{
                        background-size: 40px;
                    }
                }
            }
        }
        &.homelink-bloc-text.homelink-media-text{
            span{
                font-size: 16px;
                strong{
                    font-size: 20px;
                }
            }
        }
    }
}