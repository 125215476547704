#footer{
    width: 100%;    
    background: #667484;
    padding: 90px 0;
    height: auto;
    min-height: 380px;
    padding-bottom: 60px;
    position: relative;
    top: -100px;
    .container{
        height: 380px;
        display: flex;
        justify-content: space-between;
        >div{
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .title{
                color: #ffcc00;
                font-size: 30px;
                height: 15%;
            }
            >div.content{
                display: flex;
                flex-direction: column;
                height: 85%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                ul{
                    margin: 0;
                    padding-left: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    li{
                        padding-left: 10px;
                        color: white;
                        a{
                            text-transform: none;
                            color: white;
                        }
                        &::marker{
                            content:'-';
                        }
                    }
                }
            }
            &.left{
                width: 25%;
                .content{
                    ul{
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
                
            }
            &.right{
                width: 25%;
                .content{
                    height: 100%;
                    #logo{
                        height: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        img{
                            width: 100%;
                        }
                    }
                    #contact{
                        #footer-contact{
                            span{
                                &.contact{
                                    color: white;
                                    font-size: 35px;
                                }
                                &.number{
                                    color: #f8c620;
                                    font-size: 30px;
                                }
                                &.call{
                                    color: #f8c620;
                                    font-size: 16px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
            &.center{
                width: 25%;
                .form{
                    display: flex;
                    flex-direction: column;
                    form[name="newsletters_form"]{
                        display: flex;
                        flex-direction: column;
                        margin-top: 20px;
                        .form-group{
                            display: flex;
                            input{
                                padding: 5px;
                                &[type="email"]{
                                    width: 85%;
                                    border: none;
                                    background: #dce3e9;
                                }
                                &[type="submit"]{
                                    width: 15%;
                                    border: none;
                                    background: none;
                                    background: #a7bac9;
                                    color: #59636f;
                                    &:hover{
                                        background: #ffcc00;
                                    }
                                }
                            }
                        }
                    }
                    span{
                        color: white;
                        font-size: 18px;
                        line-height: 25px;
                        &.conditions-utilisations{
                            font-size: 10px;
                            width: 100%;
                            line-height: 15px;
                            a{
                                font-size: 10px;
                                color: white;
                                &:hover{
                                    color: black;
                                }
                            }
                        }
                    }
                }
                .logos{
                    display: flex;
                    gap: 50px;
                }
            }
        }
        
    }
}
#bottom-footer{
    position: relative;
    top: -100px;
    bottom: 0;
    background: #004689;
    padding: 10px;
    .container{
        display: flex;
        justify-content: space-between;
        >div{
            color: white;
            > a{
                color: #bdcad4;
                font-size: 10px;
                &:hover{
                    color: white;
                }
            }
            font-size: 10px;
        }
    }
}
@media (max-width: 1024px){
    #footer{
        .container{
            >div{
                &.left{
                    width: 30%;
                    .content{
                        ul{
                            li{
                                a{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    
                }
                &.right{
                    width: 30%;
                    .content{
                        #contact{
                            #footer-contact{
                                span{
                                    &.contact{
                                        color: white;
                                        font-size: 30px;
                                    }
                                    &.number{
                                        color: #f8c620;
                                        font-size: 30px;
                                    }
                                    &.call{
                                        color: #f8c620;
                                        font-size: 16px;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
                &.center{
                    width: 30%;
                    .form{
                        span{
                            font-size: 16px;
                        }
                    }
                    .logos{
                        display: flex;
                        gap: 50px;
                    }
                }
            }
            
        }
    }
    #bottom-footer{
        .container{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 10px;
            >div{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }
        }
    }
}
@media (max-width: 768px){
    #footer{
        .container{
            padding: 0 20px;
        }
    }
}
@media (max-width: 500px){
    #footer{
        top: 0;
        padding: 0;
        min-height: auto;
        padding: 40px 0;
        .container{
            height: 100%;
            flex-direction: column;
            row-gap: 40px;
            >div{
                &.left{
                    width: 100%;
                    gap: 10px;
                    >span{
                        text-align:center;
                        &::before{
                            content: '>';
                            font-size: 14px;
                            color: white;
                            margin-right: 10px;
                        }
                    }
                    .content{
                        display: none;
                        ul{
                            list-style-type: none;
                            li{
                                text-align:center;
                                list-style-type: none;
                                &::marker{
                                    content: '';
                                }
                            }
                        }
                    }
                    
                    &.open{
                        >span{
                            text-align:center;
                            &::before{
                                content: '>';
                                font-size: 14px;
                                color: #ffcc00;
                                margin-right: 10px;
                                display: inline-block;
                                -webkit-transform: rotate(90deg);
                                -moz-transform: rotate(90deg);
                                -o-transform: rotate(90deg);
                                transform: rotate(90deg);
                            }
                        }
                        .title{
                            color: white;
                        }
                        .content{
                            display: flex;
                        }
                    }
                }
                &.center{
                    width: 100%;
                    gap: 10px;
                    >span{
                        text-align:center;
                        &::before{
                            content: '>';
                            font-size: 14px;
                            color: white;
                            margin-right: 10px;
                        }
                    }
                    .content{
                        display: none;
                        ul{
                            li{
                                text-align:center;
                                &::marker{
                                    content: '';
                                }
                            }
                        }
                        .logos{
                            display: none;
                        }
                    }
                    &.open{
                        >span{
                            text-align:center;
                            &::before{
                                content: '>';
                                font-size: 14px;
                                color: #ffcc00;
                                margin-right: 10px;
                                display: inline-block;
                                -webkit-transform: rotate(90deg);
                                -moz-transform: rotate(90deg);
                                -o-transform: rotate(90deg);
                                transform: rotate(90deg);
                            }
                        }
                        .title{
                            color: white;
                        }
                        .content{
                            display: flex;
                        }
                    }
                    
                }
                &.right{
                    width: 100%;
                    .content{
                        justify-content: center;
                        align-items: center;
                        row-gap: 40px;
                        #logo{
                            width: 40%;
                        }
                    }
                }
            }
        }
    }
    #bottom-footer{
        top: 0;
    }   
}