.wrapper#nav{
    width: 100%;
    z-index: 50;
    position:absolute;
    #head{
        height: 108px;
        position:fixed;
        top: 0;
        width: 100%;
        padding-top: 10px;
        .container{
            .content{
                height: 100%;
                #logo{
                    width: 20%;
                    display: flex;
                    align-items: center;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: url('/public/build/theme/logo-white.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position:center;
                    }
                }
                #header-right-block{
                    width: 18%;
                    >div{
                        height: 50%; 
                        &#estimation{
                            background: #ffcc00;
                            >a{
                                height: 100%;
                                >span{
                                    width: 100%;
                                    display: block;
                                }
                            }
                            &:hover{
                                background: #004689;
                                >a{
                                    >span{
                                        color: #a7bac9;
                                    }
                                }

                            }
                        }  
                        span{
                            &.contact{
                                color: white;
                                font-size: 20px;
                            }
                            &.number{
                                color: #f8c620;
                                font-size: 24px;
                            }
                            &.text{
                                color: #004689;
                                font-size: 16px;
                            }
                            &.free{
                                color: #004689;
                                font-size: 24px;
                            }
                        }
                    }
                    
                }
                #nav{
                    box-sizing: border-box;
                    padding-right: 40px;
                    width: 70%;
                    justify-content: flex-end;
                    align-items: center;
                    a{
                        color: white;
                        height: 100%;
                        font-size: 22px;
                        padding: 0 20px;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        &.pro{
                            color: #a7bac9;
                        }
                        &:hover, &.active{
                          color: #ffcc00;  
                        }
                        
                    }
                }
            }
        }
        &.scroll, &.hover{
            background: rgba(102,116,132, 0.9);
            .container{
                .content{
                    #logo{
                        a{
                            background: url('/public/build/theme/logo.png');
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position:center;
                        }
                    }
                }
            }
        }
    }
    #subnav{
        display: none;
        background: #ffcc00;
        position: fixed;
        top: 118px;
        width: 100%;
        .container{
            ul{
                padding: 0;
                margin: 0;
                list-style: none;
                padding: 3px 0;
                display: flex;
                justify-content: space-between;
                width: 100%;
                li{
                    a{
                        cursor: pointer;
                        color: #004689;
                        font-size: 17px;
                        &:hover{
                            color: white;
                        }
                        &:first-letter{
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
        &.active{
            display: block;
        }
    }
}
@media (max-width: 1140px){
    .wrapper#nav{
        #head{
            .container{
                .content{
                    #header-right-block{
                        > div{
                            span{
                                &.text{
                                    color: #004689;
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                    #nav{
                        a{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px){
    .wrapper#nav{
        #head{
            .container{
                .content{
                    #header-right-block{
                        width: 18%;
                        >div{
                            height: 50%; 
                            &#estimation{
                                background: #ffcc00;
                                padding: 0 15px;
                                justify-content: space-around;
                            }  
                            span{
                                &.contact{
                                    color: white;
                                    font-size: 18px;
                                }
                                &.number{
                                    color: #f8c620;
                                    font-size: 20px;
                                }
                                &.text{
                                    color: #004689;
                                    font-size: 10px;
                                }
                                &.free{
                                    color: #004689;
                                    font-size: 24px;
                                }
                            }
                        }
                        
                    }
                    #nav{
                        width: 73%;
                        padding: 0;
                        justify-content: space-between;
                        a{
                            padding: 0 10px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        #subnav{
            top: 118px;
            .container{
                ul{
                    li{
                        a{
                            font-size: 13px;
                        }
                    }
                }
            }
            &.active{
                display: block;
            }
        }
    }
}
@media (max-width: 768px){
    .wrapper#nav-responsive{
        display: flex;
        justify-content: space-between;
        position: fixed;
        flex-direction: column;
        top: 0;
        width: 100%;
        z-index: 50;
        padding: 10px 0;
        background: rgba(102,116,132, 0.9); 
        #display-nav{
            display: flex;
            justify-content: space-between;
            >div{
                width: 33%;
                &#logo{
                    display: flex;
                    justify-content: center;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: url('/public/build/theme/logo.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position:center;
                    }
                }
                &#header-right-block{
                    
                    align-items: flex-end;
                    >div{
                        height: 100%; 
                        width: 60%;
                        justify-content: center;
                        &#estimation{
                            background: #ffcc00;
                            padding: 0 15px;
                        }  
                        span{
                            &.text{
                                color: #004689;
                                font-size: 14px;
                            }
                            &.free{
                                color: #004689;
                                font-size: 24px;
                            }
                        }
                    }
                    
                }
                &#btn-menu{
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    >div{
                        display: inline-block;
                        cursor: pointer;
                        width: 36px;
                            .bar1, .bar2, .bar3 {
                                width: 35px;
                                height: 2px;
                                background-color: white;
                                margin: 6px 0;
                                transition: 0.4s;
                            }
                    }
                    &.active{
                        >div{
                            .bar1{
                                transform: translate(0, 8px) rotate(-45deg);
                            }
                            .bar2{
                                opacity: 0;
                            }
                            .bar3{
                                transform: translate(0, -8px) rotate(45deg);
                            }
                        }
                    }
                    
                }
            }
        }
        #hide-nav{
            padding: 15px 0;
            display: none;
            width: 100%;
            justify-content: center;
            height: 100vh;
            #links{
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                .link{
                    a{
                        text-align:center;
                        width: 100%;
                        display: block;
                        color: white;
                        font-size: 20px;
                        line-height: 40px;
                        &:hover{
                            color: #ffcc00;
                        }
                    }
                    .subnav{
                        display: none;
                        ul{
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            padding: 5px 0;
                            display: flex;
                            flex-direction: column;
                            row-gap: 5px;
                            li{
                                a{
                                    font-size: 16px;
                                    line-height: 25px;
                                    &::first-letter{
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                    &:hover{
                        >a:first-child{
                            border-bottom: 1px solid white

                        }
                        >.subnav{
                            display: block;
                        }
                    }
                }
                .bloc-contact{
                    margin-top: 50px;
                    height: 50%; 
                    #estimation{
                        background: #ffcc00;
                        >a{
                            height: 100%;
                            >span{
                                width: 100%;
                                display: block;
                            }
                        }
                        &:hover{
                            background: #004689;
                            >a{
                                >span{
                                    color: #a7bac9;
                                }
                            }

                        }
                    }  
                    span{
                        &.contact{
                            color: white;
                            font-size: 20px;
                        }
                        &.number{
                            color: #f8c620;
                            font-size: 24px;
                        }
                        &.text{
                            color: #004689;
                            font-size: 16px;
                        }
                        &.free{
                            color: #004689;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        &.scroll{
            background: rgba(102,116,132, 0.9);
            #display-nav{
                >div{
                    &#logo{
                        a{
                            background: url('/public/build/theme/logo.png');
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position:center;
                        }
                    }
                }
            }
            
        }
        
    }
}
@media (max-width: 500px){
    .wrapper#nav-responsive{
        display: flex;
        justify-content: space-between;
        position: fixed;
        flex-direction: column;
        top: 0;
        width: 100%;
        z-index: 50;
        padding: 10px 0;
        background: rgba(102,116,132, 0.9); 
        #display-nav{
            display: flex;
            justify-content: flex-start;
            >div{
                width: 33%;
                &#btn-menu{
                    width: 20%;
                }
                &#logo{
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: url('/public/build/theme/logo.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position:center;
                    }
                }
                &#header-right-block{
                    width: 30%;
                    align-items: flex-end;
                    >div{
                        height: 100%; 
                        width: 100%;
                        justify-content: center;
                        &#estimation{
                            padding: 0;
                        }
                        a{
                            span{
                                width: 100%;
                                display: block;
                                &.text{
                                    font-size: 10px;
                                }
                                &.free{
                                    font-size: 20px;
                                }
                            }
                        }
                        
                    }
                    
                }
                &#btn-menu{
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    >div{
                        display: inline-block;
                        cursor: pointer;
                        width: 36px;
                            .bar1, .bar2, .bar3 {
                                width: 35px;
                                height: 2px;
                                background-color: white;
                                margin: 6px 0;
                                transition: 0.4s;
                            }
                    }
                    &.active{
                        >div{
                            .bar1{
                                transform: translate(0, 8px) rotate(-45deg);
                            }
                            .bar2{
                                opacity: 0;
                            }
                            .bar3{
                                transform: translate(0, -8px) rotate(45deg);
                            }
                        }
                    }
                    
                }
            }
        }
        #hide-nav{
            padding: 15px 0;
            display: none;
            width: 100%;
            justify-content: center;
            height: 100vh;
            #links{
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                .link{
                    a{
                        text-align:center;
                        width: 100%;
                        display: block;
                        color: white;
                        font-size: 20px;
                        line-height: 40px;
                        &:hover{
                            color: #ffcc00;
                        }
                    }
                    .subnav{
                        display: none;
                        ul{
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            padding: 5px 0;
                            display: flex;
                            flex-direction: column;
                            row-gap: 5px;
                            li{
                                a{
                                    font-size: 16px;
                                    line-height: 25px;
                                    &::first-letter{
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                    &:hover{
                        >a:first-child{
                            border-bottom: 1px solid white

                        }
                        >.subnav{
                            display: block;
                        }
                    }
                }
                .bloc-contact{
                    margin-top: 50px;
                    height: 50%; 
                    #estimation{
                        background: #ffcc00;
                        >a{
                            height: 100%;
                            >span{
                                width: 100%;
                                display: block;
                            }
                        }
                        &:hover{
                            background: #004689;
                            >a{
                                >span{
                                    color: #a7bac9;
                                }
                            }

                        }
                    }  
                    span{
                        &.contact{
                            color: white;
                            font-size: 20px;
                        }
                        &.number{
                            color: #f8c620;
                            font-size: 24px;
                        }
                        &.text{
                            color: #004689;
                            font-size: 16px;
                        }
                        &.free{
                            color: #004689;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
         &.scroll{
            #display-nav{
                >div{
                    &#logo{
                        a{
                            background: url('/public/build/theme/logo.png');
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position:center;
                        }
                    }
                }
            }
            
        }
    }
}