@import 'vars.scss';
#maintenance{
    .maintenanceHead{
        width: 100%;
        height: auto;
        img{
            width: 100%;
            display: block; 
        }
    }
    .maintenanceCaption{
        position:relative;
        top: 0;
        height: auto;
        >div:first-child{
            position:absolute;
            top: 0;
            height: 100px;
            width: 100%;
            .left, .right{
                width: 50%;
                // background: #bdcad4;
                height: 100%;
            }
            .left{
                background: linear-gradient(
                    to left,
                    transparent 0%,
                    transparent 225px,
                    #697583 225px,
                    #697583 100%
                );
                display: flex;
                justify-content: flex-end;
                span{
                    position:relative;
                    right: 225px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                    }
                }
            }
            .right{
                background: linear-gradient(
                    to right,
                    transparent 0%,
                    transparent 225px,
                    #697583 225px,
                    #697583 100%
                );
                display: flex;
                justify-content: flex-start;
                span{
                    position:relative;
                    left: 225px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                    }
                }
            }
        }
        .container{

            #maintenance-logo{
                width: 400px;
                height: 300px;
                background: #e4d9c6;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                padding: 0;
                box-sizing: border-box;
                h1{
                    color: #697583;
                    font-weight: 400;
                    text-align:center;
                    text-transform: uppercase;
                    height: auto;
                    line-height: 70px;
                    width: 100%;
                }
            }
        }
        
    }
    .maintenanceDesc{
        padding: 40px 0;   
        .container{
                display: flex;
                flex-direction: column;
                row-gap: 60px;
            .contracts{
                border: 2px solid #697583;
                &#basique{
                    h2{
                        background-color: #697583;
                        margin: 0;
                        padding: 20px 0;
                        color: white;
                        text-align:center;
                        font-weight: 400;
                        font-size: 30px;
                    }
                    #list{
                        display: flex;
                        flex-direction: column;
                        padding: 40px 10px;
                        align-items: center;
                        span{
                            display: flex;
                            justify-content: center;
                            column-gap: 10px;
                            font-size: 20px;
                            color: #697583;
                            text-align:center;
                            .point{
                                margin: 20px 0;
                                background: #e4d9c6;
                                width: 5px; 
                                height: 5px;
                                border: 1px solid transparent;
                                border-radius: 10px;
                            }
                        }
                        
                    }
                }
                &#extension{
                    h2{
                        background-color: #697583;
                        margin: 0;
                        padding: 20px 0;
                        color: white;
                        text-align:center;
                        font-weight: 400;
                        font-size: 30px;
                        span{
                            color: #ffcc00;
                            i{
                                font-weight: 600;
                            }
                        }
                    }
                    #text{
                        display: flex;
                        flex-direction: column;
                        padding: 40px 0;
                        align-items: center;
                        span{
                            display: flex;
                            justify-content: center;
                            column-gap: 10px;
                            font-size: 20px;
                            color: #697583;
                            text-align:center;
                        }
                        .table{
                            margin-bottom: 20px;
                        }
                    }
                }
                .table{
                    width: 60%;
                    padding: 50px 0;
                    padding-bottom: 0;
                    .element{
                        padding: 10px;
                        display: flex;
                        justify-content: space-between;
                        background: rgba(228,217,198, 1);
                        &:nth-child(2){
                            background: rgba(228,217,198, 0.5);
                        }
                    }
                }
                
            }
            .asterisk{
                span{
                    font-size: 14px;
                }
            }

        } 
        
    }
    .maintenanceSubscribe{
        position:relative;
        height: auto;
        >div.pictures{
            position:absolute;
            top: 0;
            height: 400px;
            width: 100%;
            .left, .right{
                width: 50%;
                // background: #bdcad4;
                height: 100%;
            }
            .left{
                background-image: url('/public/build/theme/maintenance-subscribe-left.jpg');
                background-size: cover;
                display: flex;
                justify-content: flex-end;
                margin-right: 210px;
                span{
                    position:relative;
                    right: 170px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                    }
                }
            }
            .right{
                margin-left: 210px;
                background-image: url('/public/build/theme/maintenance-subscribe-right.jpg');
                background-size: cover;
                background-position:center;
                display: flex;
                justify-content: flex-start;
                span{
                    position:relative;
                    left: 170px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                    }
                }
            }
        }
        #maintenance-subscribe{
            height: 400px;
            background: #697583;
            z-index: 10;
            width: 500px;
            box-sizing: border-box;
            padding: 0 50px;
            display: flex;
            flex-direction : column;
            text-align:center;
            justify-content: center;
            row-gap: 10%;
            h3{
                color: #e4d9c6;
                text-transform: lowercase;
                font-size: 40px;
                line-height: 60px;
                &::first-letter{
                    text-transform: uppercase;
                }
            }
            a{
                color: white;
                padding: 20px 0;
                border: 1px solid white;
                width: 100%;
                display: block;
                box-sizing: border-box;
                text-align: center;
                font-size: 20px;
                &:hover{
                    background: #e4d9c6;
                    border: 1px solid white;
                    color: white;
                }
            }
        }
    }
    .maintenanceAsterisk{
        padding: 40px 0;
        span{
            text-align:center;
            font-size: 20px;
            width: 100%;
            display: block;
            text-align:center;
        }
    }
}
@media (max-width: 768px){
    #maintenance{
        margin-top: 56px;
    }
}
@media (max-width: 500px){
    #maintenance{
        margin-top: 56px;
        .maintenanceHead{
            height: 25vh;
        }
        .maintenanceCaption{
            .container{
                padding: 0;
            }
        }
        .maintenanceDesc{
            .container{
                .contracts{
                    .table{
                        width: 100%;
                    }
                    &#extension{
                        #text{
                            .table{
                                padding: 0 10px;
                                box-sizing: border-box;
                            }
                            span{
                                line-height: 30px;
                            }
                        }
                    }
                }
                .asterisk{
                    text-align:center;
                }
            }
        }
        .maintenanceSubscribe{
            .container{
                padding: 0;
            }
        }
    }
}