.expertise{
    padding: 60px 0;
    background: #dde3e8;
    
    .container{
        display: flex;
        flex-direction: column;
        h3{
            color: #b5c2cc;
            text-align:center;
            margin-bottom: 60px;
        }
        .content-pictos{
            display: flex;
            justify-content: space-between;
            position:relative;
            
            #trait{
                position:absolute;
                top: 50px;
                left: 0;
                height: 4px;
                background-color: #b7c4d3;
                width: 100%;
            }
            .pictos{
                position:relative;
                z-index: 20;
                background-color: #dde3e8;
                width: 100px;
                img{
                    position:relative;
                    margin-bottom: 20px;
                }
                span{
                    text-align:center;
                    // color: #97abbb;
                    color: #6c7780;
                    font-size: 13px;
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}
@media (max-width: 1024px){
    .expertise{
        .container{
            .content-pictos{
                flex-wrap: wrap;
                #trait{
                    display: none;
                }
                .pictos{
                    width: 100px;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 70px;
                    }
                }
            }
        }
    }
}
@media (max-width: 768px){
    .expertise{
        .container{
            .content-pictos{
                flex-wrap: wrap;
                #trait{
                    display: none;
                }
                .pictos{
                    width: 100px;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 70px;
                    }
                }
            }
        }
    }
}
@media (max-width: 500px){
    .expertise{
        .container{
            .content-pictos{
                flex-wrap: wrap;
                row-gap: 20px;
                justify-content: center;
                column-gap: 0;
                #trait{
                    display: none;
                }
                .pictos{
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 100px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}