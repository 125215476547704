#wait{
    background: url('../theme/background.png');
    background-position: bottom;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
        .alert-success {
            color: #155724;
            background-color: #d4edda;
            border-color: #c3e6cb;
            text-align:center;
        }

        .alert {
            position: relative;
            padding: .75rem 1.25rem;
            margin-bottom: 1rem;
            border: 1px solid transparent;
            border-radius: .25rem;
            display: block;
        }
        #logo{
            width: 100%;
            height: 70vh;
            .container{
                display: flex;
                justify-content: center;
                align-items: flex-end;
            }
            
        }
        #text{
            margin-top: 200px;
            color: #697583;
            display: flex;
            flex-direction: column;
            gap: 0;
            p{
                text-align:center;
                font-size: 35px;
                line-height: 50px;
                margin-bottom: 0;
                margin-top: 70px;
                &.number, &.free-call{
                    margin: 0;
                }
                &.number{
                    color: #ffcb08;
                    font-size: 45px;
                }
                &.free-call{
                    font-size: 20px;
                }
            }
        }
        #contactForm{
            background: #ffcc00;
            box-sizing: border-box;
            padding-bottom: 30px;
            .container{
                p{
                    color: white;
                    text-align: center;
                    font-size: 35px;
                    line-height: 50px;
                    margin: 0;
                    padding: 30px 0;
                    box-sizing: border-box;
                }
                #form{
                    
                    form{
                        display: flex;
                        flex-direction: column;
                        row-gap: 25px;
                        div{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            flex-wrap: wrap ;
                            row-gap: 25px;
                            input{
                                width: 48%;
                                border: none;
                                font-family: 'Century-Gothic';
                                color: #667484;
                                font-size: 20px;
                                padding: 10px 0 10px 10px;
                            }
                            textarea{
                                width: 100%;
                                height: 300px;
                                resize: none;
                                border: none;
                                font-family: 'Century-Gothic';
                                color: #667484;
                                font-size: 20px;
                                padding: 10px;
                            }
                            &.submit{
                                display: flex;
                                justify-content: center;
                                .valid{
                                    border: 1px solid white;
                                    background: none;
                                    color: white;
                                    cursor: pointer;
                                    width: 25%;
                                    &:hover{
                                        background: #004689;
                                        color: white;
                                        border: 1px solid #004689;
                                    }
                                }
                            }
                            &.checkboxField{
                                div{
                                    display: flex;
                                    flex-direction: row-reverse;
                                    width: 100%;
                                    justify-content: flex-end;
                                        gap: 10px;
                                    input{
                                        width: auto;
                                    }
                                    label{
                                        font-family: 'Century-Gothic';
                                        color: #667484;
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                   
                }
            }
        }
        #gamme{
            padding: 50px 0;
            h2{
                color: #bac9d5;
                font-size: 45px;
                text-align:center;
                text-transform: uppercase;
                font-weight: normal;
                padding: 50px 0;
            }
            #list{
                display: flex;
                width: 100%;
                height: auto;
                justify-content: space-between;
                >span{
                    width: 20%;
                    cursor: pointer;
                    height: 190px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    .text-hover{
                        text-transform: uppercase;
                        text-align:center;
                        display: none;
                    }
                    &.cloture{
                        background: url('../theme/gamme-cloture.jpg');
                        background-repeat: no-repeat;
                        background-position:top center;
                        &:hover {
                            background: url('../theme/gamme-cloture-hover.jpg');
                            background-repeat: no-repeat;
                            background-position:top center;
                            .text-hover{
                                display: block;
                                color: #003d7c;
                            }
                        }
                        
                    }
                    &.porte-volet{
                        background: url('../theme/gamme-porte-volet.jpg');
                        background-repeat: no-repeat;
                        background-position:top center;
                        &:hover {
                            background: url('../theme/gamme-porte-volet-hover.jpg');
                            background-repeat: no-repeat;
                            background-position:top center;
                            .text-hover{
                                display: block;
                                color: #ffc20e;
                            }
                        }
                        
                    }
                    &.garage{
                        background: url('../theme/gamme-garage.jpg');
                        background-repeat: no-repeat;
                        background-position:top center;
                        &:hover {
                            background: url('../theme/gamme-garage-hover.jpg');
                            background-repeat: no-repeat;
                            background-position:top center;
                            .text-hover{
                                display: block;
                                color: #f1592e;
                            }
                        }
                        
                    }
                    &.carport{
                        background: url('../theme/gamme-carport.jpg');
                        background-repeat: no-repeat;
                        background-position:top center;
                        &:hover {
                            background: url('../theme/gamme-carport-hover.jpg');
                            background-repeat: no-repeat;
                            background-position:top center;
                            .text-hover{
                                display: block;
                                color: #4db848;
                            }
                        }
                        
                    }
                    &.mobilier{
                        background: url('../theme/gamme-mobilier.jpg');
                        background-repeat: no-repeat;
                        background-position:top center;
                        &:hover {
                            background: url('../theme/gamme-mobilier-hover.jpg');
                            background-repeat: no-repeat;
                            background-position:top center;
                            .text-hover{
                                display: block;
                                color: #967cab;
                            }
                        }
                        
                    }
                }   
            }
            
        }
        #contact{
            height: 273px;
            width: 100%;
            display: block;
            background: url('../theme/background_magasin.jpg');
            background-size: cover;
            .container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 50px;
                >div{
                    display: flex;
                    justify-content: flex-start;
                    gap: 25px;
                    >div{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        span{
                            &.title{
                                color: #ffcc00;
                                font-size: 23px;
                            }
                            &.text{
                                color: white;
                                font-size: 18px;
                            }
                        }
                    }

                }
            }
        }
        #social{
            height: 65px;
            width: 100%;
            display: block;
            background: #667484;
            .container{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 40px;
                >span{
                    color: #a7bac9;
                    font-size: 30px;
                }
                a.social{
                    width: 44px;
                    height: 43px;
                    display: block;
                    &.social-fb{
                        background: url('../theme/fb.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/fb-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                    &.social-insta{
                        background: url('../theme/insta.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/insta-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                    &.social-linkedin{
                        background: url('../theme/linkedin.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/linkedin-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                    &.social-tiktok{
                        background: url('../theme/tiktok.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/tiktok-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
        #map{
            width: 100%;
            height: 600px;
            z-index: 0;
        }
        #footer{
            background: #004689;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            position:relative;
            top: -4px;
            span{
                color: white;
            }
        }
}


@media (max-width: 1024px){
    
    #contact .container > div > img{
        display: none;
    }
    #wait{
        #gamme{
            padding: 50px 0;
            h2{
                color: #bac9d5;
                font-size: 45px;
                text-align:center;
                text-transform: uppercase;
                font-weight: normal;
                padding: 50px 0;
            }
            #list{
                display: flex;
                width: 100%;
                height: auto;
                justify-content: space-between;
                >span{
                    width: 20%;
                    cursor: pointer;
                    height: 190px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    .text-hover{
                        text-transform: uppercase;
                        text-align:center;
                        display: block;
                    }
                    &.cloture{
                        background: url('../theme/gamme-cloture-hover.jpg');
                        background-repeat: no-repeat;
                        background-position:top center;
                        .text-hover{
                            color: #003d7c;
                        }
                        
                    }
                    &.porte-volet{
                        background: url('../theme/gamme-porte-volet-hover.jpg');
                        background-repeat: no-repeat;
                        background-position:top center;
                        .text-hover{
                            color: #ffc20e;
                        }
                        
                    }
                    &.garage{
                        background: url('../theme/gamme-garage-hover.jpg');
                        background-repeat: no-repeat;
                        background-position:top center;
                        .text-hover{
                            color: #f1592e;
                        }
                        
                    }
                    &.carport{
                        background: url('../theme/gamme-carport-hover.jpg');
                        background-repeat: no-repeat;
                        background-position:top center;
                        .text-hover{
                            color: #4db848;
                        }
                        
                    }
                    &.mobilier{
                            background: url('../theme/gamme-mobilier-hover.jpg');
                            background-repeat: no-repeat;
                            background-position:top center;
                        .text-hover{
                            color: #967cab;
                        }
                        
                    }
                }   
            }
            
        }
    }
}