@import 'vars.scss';
#professionnel{
    .professionnelHead{
        width: 100%;
        height: auto;
        img{
            width: 100%;
            display: block;
        }
    }
    .professionnelCaption{
        position:relative;
        top: 0;
        height: auto;
        >div:first-child{
            position:absolute;
            top: 0;
            height: 100px;
            width: 100%;
            .left, .right{
                width: 50%;
                // background: #bdcad4;
                height: 100%;
            }
            .left{
                background: linear-gradient(
                    to left,
                    transparent 0%,
                    transparent 225px,
                    #dde3e8 225px,
                    #dde3e8 100%
                );
                display: flex;
                justify-content: flex-end;
                span{
                    position:relative;
                    right: 225px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                    }
                }
            }
            .right{
                background: linear-gradient(
                    to right,
                    transparent 0%,
                    transparent 225px,
                    #dde3e8 225px,
                    #dde3e8 100%
                );
                display: flex;
                justify-content: flex-start;
                span{
                    position:relative;
                    left: 225px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                    }
                }
            }
        }
        .container{
            #professionnel-logo{
                width: 400px;
                height: 252px;
                background: #a7bac9;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                padding: 20px 20px;
                box-sizing: border-box;
                h1{
                    color: white;
                    font-weight: 400;
                    text-align:center;
                    text-transform: uppercase;
                    width: 100%;
                }
                >span{
                    color: white;
                    width: 100%;
                    text-align:center;
                    line-height: 30px;
                    font-size: 20px;
                }
            }
        }
        
    }
    .professionnelDesc{
        padding: 20px 0;
        >div{
            width: auto;
            text-align:center;
            font-size: 20px;    
            color: #667484;
            line-height: 40px;
            display: flex;
            justify-content: center;
            >div{
                width: 1024px;
            }
        }
    }
    .professionnelMore{
        padding: 20px 0;
        background: #dde3e8;
        >div{
            width: auto;
            text-align:center;
            font-size: 20px;    
            color: #667484;
            line-height: 40px;
            display: flex;
            justify-content: center;
            >div{
                width: 1024px;
            }
        }
    }
    .professionnelCatalog{
        background: white;
        padding: 40px 0;
        .container{
            >span{
                font-size: 25px;
                color: #a7bac9;
                text-align:center;
                width: 100%;
                display: block;
                line-height: 40px;
            }
            .catalogs{
                padding: 20px 0;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 30px;
                .catalog{
                    position:relative;
                    cursor: pointer;
                    img{
                        width: auto;
                        height: 150px;
                        display: block;
                    }
                    .hover{
                        display: none;
                        width: 100%;
                        height: 100%;
                        position:absolute;
                        top: 0;
                        left: 0;
                    }
                    &:hover{
                        .hover{
                            display: flex;
                            background: rgba(151,171,187,0.7);
                            a{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 35px;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
    .professionnelContract{
        width: 100%;
        background: linear-gradient(to right, #667484 0%, #667484 50%, #ffcc00 50%, #ffcc00 100%);
        padding: 60px 0;
        .container{
            height: 170px;
            >div{
                width: 50%;
                position:relative;
                display: flex;
                flex-direction: column;
                &.contract{
                    justify-content: space-between;
                    h3{
                        color: #f8c620;
                        font-size: 35px;
                        text-align:center;
                    }
                    div.text{
                        a{
                            font-size: 20px;
                            text-align:center;
                            color: white;
                            line-height: 37px;
                        }
                    }
                    
                }
                &.contact{
                    justify-content: space-between;
                    h3{
                        color: #003e7c;
                        font-size: 30px;
                        text-align:center;
                    }
                    div.text{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 20px;
                        span{
                            &.desc{
                                font-size: 17px;
                                color: #667484;
                                line-height: 30px;
                            }
                            &.contact{
                                font-size: 20px;
                                color: #667484;
                                b{
                                    color: white;
                                    font-size: 40px;
                                    font-weight: 600;
                                }
                            }
                        }
                        a{
                            text-align:center;
                            color: white;
                            padding: 10px 30px;
                            border: 1px solid white;
                            &:hover{
                                background: #a8b6c6;
                                color: white;
                                border: 1px solid white;
                            }
                        }
                    }
                }                
            }
        }
        
    }
    .professionnelImplementations{
        background: #dde3e8;
        padding: 10px 0;
        .text{
            height: auto;
            background: #97abbb;
            padding: 10px 0;
            .container{
                display: flex;
                >span{
                    color: white;
                    font-size: 20px;
                    text-align:center;
                    width: 100%;
                }
            }
        }
        .gallery{
            .links{
                padding: 20px 0;
                .container{
                    display: flex;
                    justify-content: space-between;
                    .filter-btn{
                        color: #667484;
                        &.mixitup-control-active{
                            color: #ffcc00;
                        }
                    }
                }
            }
            .images{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                column-gap: 0.5%;
                row-gap: 0.5%;
                row-gap: 10px;
                height: auto;
                overflow: hidden;
                &.expand{
                    height: auto;
                }
                >div.mix{
                    width: 24.6%;
                    height: auto;
                    max-height: 310px;
                    position:relative;
                    div.more-hover{
                        position:absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        
                        display: none;
                        cursor: pointer;
                        >svg{
                            color: white;
                            font-size: 40px;
                        }
                        >h2{
                            position:absolute;
                            bottom: 0;
                            width: 100%;
                            text-align:center;
                            text-transform: capitalize;
                            color: white;
                            font-size: 20px;
                        }
                    }
                    &:hover{
                        div.more-hover{
                            display: none;
                        }
                    }
                    img{
                        width: 100%;
                        height: auto;
                        max-height: 310px;
                        overflow: hidden;
                    }
                }
            }
            .more-implementations{
                padding: 15px 0;
                .container{
                    display: flex;
                    justify-content: center;
                    span{
                        color: #697583;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px){
    #professionnel{
        .professionnelDesc, .professionnelMore{
            >div{
                p{
                    width: 768px;
                    font-size: 16px;
                }
            }
        }
    }
}
@media (max-width: 768px){
    #professionnel{
        margin-top: 56px;
        .professionnelDesc, .professionnelMore{
            padding: 0 10px;
            >div{
                p{
                    width: 100%;
                    font-size: 15px;
                }
            }
        }
        .professionnelContact{
            span{
                font-size: 20px;
                &.telNumber{
                    font-size: 25px;
                }
            }
        }
    }
}
@media (max-width: 500px){
    #professionnel{
        margin-top: 56px;
        .professionnelCaption{
            >div:first-child{
                display: none;
            }
            .container{
                width: 100%;
                padding: 0;
                #professionnel-logo{
                    width: 100%;
                }
            }
        }
        .professionnelDesc{
            div{
                p{
                    line-height: 25px;
                }
            }
        }
        .professionnelMore{
            div{
                p{
                    line-height: 25px;
                }
            }
        }
        .professionnelContract{
            background: none;
            .container{
                height: auto;
                flex-direction: column;
                padding: 0;
                >div{
                    width: 100%;
                    padding: 20px 10px;
                    box-sizing: border-box;
                    &.contract{
                        background: #667484 ;
                    }
                    &.contact{
                        background: #ffcc00 ;
                    }
                }
            }
        }
    }
}