@import 'vars.scss';
#about{
    .aboutHead{
        width: 100%;
        height: auto;
        img{
            width: 100%;
            display: block;
        }
    }
    .aboutCaption{
        position:relative;
        top: 0;
        height: auto;
        >div:first-child{
            position:absolute;
            top: 0;
            height: 120px;
            width: 100%;
            .left, .right{
                width: 50%;
                // background: #bdcad4;
                height: 100%;
            }
            .left{
                background: linear-gradient(
                    to left,
                    transparent 0%,
                    transparent 225px,
                    #bdcad4 225px,
                    #bdcad4 100%
                );
                display: flex;
                justify-content: flex-end;
                span{
                    position:relative;
                    right: 250px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                        &:hover{
                            color: #667484;
                        }
                    }
                }
            }
            .right{
                background: linear-gradient(
                    to right,
                    transparent 0%,
                    transparent 225px,
                    #bdcad4 225px,
                    #bdcad4 100%
                );
                display: flex;
                justify-content: flex-start;
                span{
                    position:relative;
                    left: 250px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                        &:hover{
                            color: #667484;
                        }
                    }
                }
            }
        }
        .container{

            #about-logo{
                width: 400px;
                height: auto;
                background: #667484;
                display: flex;
                flex-direction: column;
                align-items: center;
                box-sizing: border-box;
                img{
                    width: 100%;
                }
                h1{
                    color: white;
                    font-weight: 400;
                    text-align:center;
                    width: 100%;
                    span{
                        text-transform: uppercase;
                        p{
                            margin: auto;
                        }
                    }
                }
            }
        }
        
    }
    .aboutDesc{
        padding: 40px 0;
        >div{
            width: auto;
            text-align:center;
            font-size: 20px;    
            color: #697583;
            line-height: 40px;
            display: flex;
            justify-content: center;
            >p{
                width: 1140px;
            }
        }
    }
    .aboutMore{
        background: #667484;
        width: 100%;
        padding: 20px 0;
        .container{
            display: flex;
            justify-content: center;
            span{
                width: auto;
                color: white;
                font-size: 20px;
                line-height: 33px;
                &.img{
                    width: 20%;
                    display: flex;
                    justify-content: center;
                }
            }
            
        }
    }
    .aboutMovie{
        padding: 60px 0;
        .container{
            display: flex;
            justify-content: center;
        }
    }
    .notices{
        background: none;
    }
    .ranges{
        padding: 50px 0;
        .container{
            color: #bdcad4;
            h4{
                padding-bottom: 50px;
                text-align: center;
                width: 100%;
            }
            .elementRange{
                width: 380px;
            }
        }
    }
}
@media (max-width: 1024px){
    #about{
        .aboutCaption{
            >div:first-child{
                .left{
                    span{
                        right: 0;
                        width: 384px;
                        justify-content: left;
                    }
                }
                .right{
                    span{
                        left: 0;
                        width: 384px;
                        justify-content: right;
                    }
                }
            }
        }
        .aboutDesc{
            padding: 40px 0;
            >div{
                >p{
                    width: 768px;
                }
            }
        }
        .aboutMore{
            .container{
                justify-content: space-between;
                span{
                    width: auto;
                    color: white;
                    font-size: 18px;
                    line-height: 23px;
                    width: 70%;
                    &.img{
                        width: 20%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 80%;
                            height: auto;
                            display: block;
                        }
                    }
                }
                
            }
        }
        .ranges{
            padding: 50px 0;
            .container{
                color: #bdcad4;
                h4{
                    padding-bottom: 50px;
                    text-align: center;
                    width: 100%;
                }
                .elementRange{
                    width: 50%;
                }
            }
        }
    }
}
@media (max-width: 768px){
    #about{
        .aboutCaption{
            >div:first-child{
                .left{
                    span{
                        display: none;
                        right: 0;
                        width: 100%;
                        justify-content: left;
                    }
                }
                .right{
                    span{
                        display: none;
                        left: 0;
                        width: 100%;
                        justify-content: right;
                    }
                }
            }
        }
        .aboutDesc{
            padding: 40px 10px;
            >div{
                >p{
                    width: 768px;
                }
            }
        }
        .aboutMore{
            .container{
                justify-content: space-between;
                span{
                    width: auto;
                    color: white;
                    font-size: 18px;
                    line-height: 23px;
                    width: 70%;
                    br{
                        display: none;
                    }
                    &.img{
                        width: 20%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 80%;
                            height: auto;
                            display: block;
                        }
                    }
                }
                
            }
        }
        .ranges{
            padding: 50px 0;
            .container{
                color: #bdcad4;
                padding: 0;
                h4{
                    padding-bottom: 50px;
                    text-align: center;
                    width: 100%;
                }
                .elementRange{
                    width: 50%;
                }
            }
        }
    }
}
@media (max-width: 500px){
    #about{
        margin-top: 56px;
        .aboutHead{
            height: auto;
        }
        .aboutCaption{
            >div:first-child{
                display: none;
            }
            .container{
                width: 100%;
                padding: 0;
                #about-logo{
                    width: 100%;
                }
            }
        }
        .ranges{
            .container{
                .elementRange{
                    width: 100%;
                    height: 200px;
                }
            }
        }
        .aboutDesc{
            padding: 40px 10px;
            >div{
                >p{
                    width: 100%;
                }
            }
        }
        .aboutMore{
            .container{
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                row-gap: 10px;
                span{
                    width: auto;
                    color: white;
                    font-size: 18px;
                    line-height: 23px;
                    width: 100%;
                    text-align:center;
                    &.img{
                        width: 20%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }
                    br{
                        display: none;
                    }
                }
                
            }
        }
    }
}