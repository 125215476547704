.partners{
    width: 100%;
    height: 200px;
    padding: 60px 0;
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        h4{
            color: #dce3e9;
            text-align:center;
            height: auto;
            margin-bottom: 60px;
        }
        .content-partners{
            width: 96%;
            .glider-contain{
                .gliderPartners{
                    display: flex;
                    .glide-partners{
                        display: flex;
                        justify-content: center;
                        position:relative;
                        > a{
                            position:absolute;
                            top: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                        }
                        img{
                            width: auto;
                            max-height: 75px;
                        }
                    }
                }
                .glider-next, .glider-prev{
                    font-size: 16px;
                    padding: 5px 7px;
                    border: 1px solid black;
                    border-radius: 15px;
                }
                .glider-next{
                    right: -20px;
                }
                .glider-prev{
                    left: -20px;
                }
                .listPartners{
                    display: flex;
                    justify-content: center;
                    >div{
                        width: auto;
                        min-width: 273px;
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px){
    .partners{
        .container{
            .content-partners{
                .glider-contain{
                    .glide-partners{
                        img{
                            max-height: 60px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px){
    .partners{
        .container{
            .content-partners{
                .glider-contain{
                    .listPartners{
                        >div{
                            min-width: 30%;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 500px){
    .partners{
        .container{
            .content-partners{
                .glider-contain{
                    .glider-next{
                        right: 0;
                    }
                    .glider-prev{
                        left: 0;
                    }
                    .listPartners{
                        display: flex;
                        justify-content: space-between;
                        >div{
                            width: auto;
                            min-width: 25%;
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}