.notices{
    width: 100%;
    height: auto;
    min-height: 300px;
    padding: 60px 0;
    background: #edf1f4;
    .container{
        h4{
            color: #004689;
            text-align:center;
            height: 20%;
            margin-bottom: 60px;
        }
        .content-notices{
            height: auto;
            min-height: 300px;
            .glider-contain{
                height: auto;
                min-height: 300px;
                .gliderModulesNotices{
                    .glide-notice-modules{
                        display: flex;
                        flex-direction: column;
                        row-gap: 30px;
                        .header{
                            display: flex;
                            flex-direction: column;
                            span{
                                text-align:center;
                                &.name{
                                    color: #727f8e;
                                    font-size: 25px;
                                }
                                &.date{
                                    color: #bdcad4;
                                    font-size: 16px;
                                }
                            }
                        }
                        .content{
                            display: flex;
                            justify-content: center;
                            span{
                                text-align:center;
                                font-size: 19px;
                                color: #727f8e;
                                line-height: 35px;
                                width: 80%;
                            }
                        }
                    }
                }
                #notices-link{
                    display: flex;
                    justify-content: center;
                    gap: 15px;
                    a, span{
                        color: #bdcad4;
                        cursor: pointer;
                        &:hover{
                            color: #777;
                        }
                    }
                }
                .dots-module-notices{
                    margin-top: 50px;
                    .glider-dot{
                        border: 1px solid #a7bac9;
                        background: none;
                        &.active{
                            background: #a7bac9;
                        }
                    }
                }
            }
        }   
    }
}
@media(max-width: 768px){
    .notices{
        .container{
            .content-notices{
                .glider-contain{
                    .gliderModulesNotices{
                        .glide-notice-modules{
                            .content{
                                span{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    #notices-link{
                        margin-top: 20px;
                        flex-direction: column;
                        span{
                            display: none;
                        }
                        a{
                            font-size: 16px;
                            text-align :center;
                        }
                    }
                }
            }
        }
    }
    .glider::-webkit-scrollbar{
        display: none;
    }
}
@media(max-width: 500px){
    .notices{
        .container{
            .content-notices{
                .glider-contain{
                    .gliderModulesNotices{
                        .glide-notice-modules{
                            .content{
                                span{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    #notices-link{
                        margin-top: 20px;
                        flex-direction: column;
                        span{
                            display: none;
                        }
                        a{
                            font-size: 16px;
                            text-align :center;
                        }
                    }
                }
            }
        }
    }
    .glider::-webkit-scrollbar{
        display: none;
    }
}