@import 'vars.scss';
#range{
    .rangeHead{
        width: 100%;
        height: auto;
        img{
            width: 100%;
            display: block;
        }
    }
    .rangeCaption{
        position:relative;
        top: 0;
        height: auto;
        >div:first-child{
            position:absolute;
            top: 0;
            height: 100px;
            width: 100%;
            .left, .right{
                width: 50%;
                // background: #bdcad4;
                height: 100%;
            }
            .left{
                background: linear-gradient(
                    to left,
                    transparent 0%,
                    transparent 225px,
                    #bdcad4 225px,
                    #bdcad4 100%
                );
                display: flex;
                justify-content: flex-end;
                span{
                    position:relative;
                    right: 225px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                        &:hover{
                            color: #667484;
                        }
                    }
                }
            }
            .right{
                background: linear-gradient(
                    to right,
                    transparent 0%,
                    transparent 225px,
                    #bdcad4 225px,
                    #bdcad4 100%
                );
                display: flex;
                justify-content: flex-start;
                span{
                    position:relative;
                    left: 225px;
                    width: 342px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        color: white;
                        text-transform: none;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 20px;
                        &:hover{
                            color: #667484;
                        }
                    }
                }
            }
        }
        .container{

            #range-logo{
                width: 400px;
                height: 300px;
                background: #004689;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                padding: 20px 0;box-sizing: border-box;
                img{
                    height: 160px;
                    width: auto;
                }
                h1{
                    color: white;
                    font-weight: 400;
                    text-align:center;
                    span{
                        text-transform: uppercase;
                    }
                }
            }
        }
        
    }
    .rangeDesc{
        padding: 40px 0;
        >div{
            width: auto;
            text-align:center;
            font-size: 20px;    
            color: #697583;
            line-height: 40px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            >div, p{
                width: 1024px;
            }
        }
    }
    .rangeMore{
        padding: 40px 0;
        .container{
            .more{
                border: 2px solid #697583;
                box-sizing: border-box;
                span.title{
                    background: #697583;
                    width: 100%;
                    padding: 10px;
                    color: white;
                    box-sizing: border-box;
                    display: block;
                    text-align: center;
                    font-size: 30px;
                    svg{
                        color: #ffcc00;
                    }
                }
                div.content{
                    padding: 30px 0;
                    p{
                        text-align:center;
                        font-size: 20px;
                        color: #697583;
                        line-height: 40px;
                    }
                }
            }
        }
    }
    .rangeBlocs{
        padding-top: 40px;
        .bloc{
            display: flex;
            justify-content: center;
            .container{
                width: 1140px;
                display: flex;
                justify-content: space-between;
                >div{
                    &.left{
                        padding-right: 10px;
                    }
                    &.right{
                        padding-left: 10px;
                    }
                    width: 50%;
                    img{
                        width: 100%;
                        display: block;
                    }
                    .text{
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-family: 'century-gothic';
                        &.alignRight{
                            text-align:right;
                        }
                        &.alignLeft{
                            text-align:left;
                        }
                        p{
                            &:first-child{
                                line-height: 35px;
                            }
                            display: flex;
                            flex-direction: column;
                            line-height: 30px;
                        }
                        br{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &.range-pergola-carport{
        .rangeMore{
            background: url('/public/build/theme/background-more-pergola.jpg');
            background-size: cover;
            .container{
                .more{
                    border: none;
                    .title{
                        display: none;
                    }
                    div.content{
                        padding: 30px 0;
                        p{
                            text-align:center;
                            font-size: 20px;
                            color: white;
                        }
                    }
                }
            }
        }
    }
    &.range-déco-et-mobiler-d-extérieur{
        .rangeDesc{
            >div.contain-description-range{
                >div{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    font-size: 18px;
                    table{
                        width: 1140px !important;
                        margin: 0 !important;
                        border: none;
                        tbody{
                            border: none;   
                            tr{
                                td{
                                    border: none;
                                    p{
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .rangeCatalogs{
        background: #bdcad4;
        padding: 40px 0;
        .container{
            >span{
                font-size: 20px;
                color: white;
                text-align:center;
                width: 100%;
                display: block;
                line-height: 40px;
            }
            .catalogs{
                padding: 20px 0;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 30px;
                .catalog{
                    position:relative;
                    cursor: pointer;
                    img{
                        width: auto;
                        height: 150px;
                        display: block;
                    }
                    .hover{
                        display: none;
                        width: 100%;
                        height: 100%;
                        position:absolute;
                        top: 0;
                        left: 0;
                    }
                    &:hover{
                        .hover{
                            display: flex;
                            background: rgba(94,104,119,0.7);
                            a{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 35px;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
    .rangeEstimateText{
        background: #667484;
        padding: 40px 0;
        .container{
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            >span{
                font-size: 20px;
                color: white;
                text-align:center;
                width: 100%;
                display: block;
                line-height: 40px;
                a{
                    text-transform: lowercase;
                    font-size: 20px;
                }
                &.com{
                    color: #bccad4;
                    font-weight: 600;
                }
            }
            .contact{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                row-gap: 20px;
                >span{
                    color: white;
                    width: 100%;
                    display: block;
                    font-size: 20px;
                    text-align:center;
                }
                span.btn-estimate{
                    width: 300px;
                    box-sizing: border-box;
                    padding: 10px 0;
                    background: #ffcc00;
                    border: 4px solid #ffcc00;
                    border-radius: 30px;
                    position:relative;
                    a{
                        text-transform: none;
                        color: white;
                        font-size: 20px;
                    }
                    .right{
                        position:absolute;
                        color: #ffcc00;
                        right: 0;
                        top: 0;
                        background: #667484;
                        font-size: 20px;
                        border: 1px solid transparent;
                        padding: 12px;
                        border-radius: 25px;
                    }
                    .left{
                        position:absolute;
                        color: #ffcc00;
                        left: 0;
                        top: 0;
                        background: #667484;
                        font-size: 20px;
                        border: 1px solid transparent;
                        padding: 12px;
                        border-radius: 25px;
                    }
                    &:hover{
                        background: white;
                        border: 4px solid white;
                        a{
                            color: #ffcc00;
                        }
                        .right, .left{
                            color: white;
                        }
                    }
                }
                .telContact{
                    display: flex;
                    flex-direction: column;
                    span{
                        color: white;
                        font-size: 20px;
                        &.number{
                            font-size: 24px;
                            color: #ffcc00;
                        }
                    }
                }
            }
        }
    }
    .rangeImplementations{
        .text{
            height: auto;
            background: #97abbb;
            padding: 10px 0;
            .container{
                display: flex;
                >span{
                    color: white;
                    font-size: 20px;
                    text-align:center;
                    width: 100%;
                }
            }
        }
        .gallery{
            background: #edf1f4;
            .links{
                padding: 20px 0;
                .container{
                    display: flex;
                    justify-content: space-between;
                    -webkit-justify-content: space-between;
                    .filter-btn{
                        cursor: pointer;
                        color: #667484;
                        &.mixitup-control-active, &:hover{
                            color: #ebbc00;
                        }
                    }
                    .btn-link{
                        display: flex;
                        gap: 10px;
                    }
                }
            }
            .images{
                display: flex;
                flex-wrap: wrap;
                row-gap: 5px;
                height: 705px;
                overflow: hidden;
                justify-content: space-between;
                &.expand{
                    height: auto;
                }
                >div.mix{
                    width: 24.85%;
                    height: 350px;
                    position:relative;
                    div.more-hover{
                        position:absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        
                        display: none;
                        cursor: pointer;
                        >svg{
                            color: white;
                            font-size: 40px;
                        }
                        >h2{
                            position:absolute;
                            bottom: 0;
                            width: 100%;
                            text-align:center;
                            color: white;
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                    &:hover{
                        div.more-hover{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            background: rgba($background-cloture, 0.7);
                        }
                    }
                    img{
                        width: 100%;
                        height: auto;
                        max-height: 310px;
                        overflow: hidden;
                    }
                }
            }
            .more-implementations{
                padding: 15px 0;
                .container{
                    display: flex;
                    justify-content: center;
                    span{
                        color: #697583;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .rangeContract{
        width: 100%;
        background: linear-gradient(
            to right,
            #667484 0%,
            #667484 50%,
            #bdcad4 50%,
            #bdcad4 100%
        );
        padding: 60px 0;
        .container{
            >div{
                width: 50%;
                &.contract{
                    position:relative;
                    a{
                        position:absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                    h3{
                        color: #f8c620;
                    }
                    div.text{
                        height: 225px;
                        span{
                            font-size: 22px;
                            text-align:center;
                            color: white;
                            
                        }
                        span.arrow{
                            width: 100%;
                            margin-top: 25px;
                            svg{
                                padding: 9px 12px;
                                border: 4px solid #ffcc00;
                                border-radius: 41px;
                                color: #ffcc00;
                                font-size: 30px;
                                cursor: pointer;
                                &:hover{
                                    border: 2px solid white;
                                    color: white;
                                }
                            }
                        }
                    }
                    &:hover{
                        div.text{
                            span.arrow{
                                svg{
                                    border: 4px solid white;
                                    color: white;
                                }
                            }
                        }
                    }
                    
                }
                &.sav{
                    h3{
                        color: #003e7c;
                    }
                    div.text{
                        span{
                            &.desc{
                                font-size: 17px;
                                color: #667484;
                                line-height: 30px;
                                br{
                                    display: none;
                                }
                            }
                            &.contact{
                                font-size: 20px;
                                color: #667484;
                                b{
                                    color: white;
                                    font-size: 22px;
                                    font-size: 600;
                                }
                            }
                        }
                    }
                }
                h3{
                    font-size: 45px;
                    text-align:center;
                    margin-bottom: 60px;
                }
                div.text{
                    height: 200px;
                    justify-content: space-between;
                    align-items: center;
                    span{
                        font-size: 20px;
                        text-align:center;
                        &.desc{
                            font-size: 17px;
                            br{
                                display: none;
                            }
                        }
                        &.point{
                            content: '';
                            width: 12px;
                            height: 12px;
                            background-color: #ffcc00;
                            border-radius: 50%;
                        }
                    }
                }
                
            }
        }
    }
}
@media (max-width: 1024px){
    #range{
        .rangeCaption{
            >div:first-child{
                .left{
                    span{
                        right: 140px;
                    }
                }
                .right{
                    span{
                        left: 140px;
                    }
                }
            }
        }
        .rangeDesc{
            >div{
                >div, p{
                    width: 768px;
                }
            }
        }
        .rangeBlocs{
            .bloc{
                .container{
                    >div{
                        &.left{
                            padding-right: 0;
                        }
                        &.right{
                            padding-left: 0;
                        }
                        .text{
                            &.alignRight{
                                padding: 0 10px;
                            }
                            &.alignLeft{
                                padding: 0 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px){
    #range{
        margin-top: 70px;
        .rangeCaption{
            >div:first-child{
                .left{
                    background: #bdcad4;
                    justify-content: flex-start;
                    span{
                        right: 0;
                        width: auto;
                        padding-left: 10px;
                    }
                }
                .right{
                    background: #bdcad4;
                    justify-content: flex-end;
                    span{
                        left: 0;
                        width: auto;
                        padding-right: 10px;
                    }
                }
            }
            .container{
                #range-logo{
                    width: 300px;
                    height: 300px;
                    background: #004689;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    padding: 20px 0;box-sizing: border-box;
                    z-index: 30;
                    img{
                        height: 160px;
                        width: auto;
                    }
                    h1{
                        color: white;
                        font-weight: 400;
                        text-align:center;
                        font-size: 25px;
                        span{
                            text-transform: uppercase;
                        }
                    }
                }
            }                                       
        }
        .rangeDesc{
            padding: 50px 10px;
            box-sizing: border-box;
            >div{
                >div, p{
                    width: 100%;
                    span:first-child{
                        line-height: 40px;
                    }
                }
                p{
                    br{
                        display: none;
                    }
                }
            }
        }
        .rangeBlocs{
            .bloc{
                .container{
                    >div{
                        &.left{
                            padding-right: 0;
                        }
                        &.right{
                            padding-left: 0;
                        }
                        .text{
                            &.alignRight{
                                padding: 0 10px;
                            }
                            &.alignLeft{
                                padding: 0 10px;
                            }
                        }
                    }
                }
            }
        }
        .rangeImplementations{
            .gallery{
                .images{
                    height: 640px;
                    column-gap: 4px;
                    >div.mix{
                        width: 32.98%;
                        height: 210px;
                    }
                }
            }
        }
        .rangeContract{
            .container{
                >div{
                    &.sav{
                        h3{
                            color: #003e7c;
                        }
                        div.text{
                            span{
                                &.desc{
                                    font-size: 17px;
                                    color: #667484;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .rangeEstimateText{
            .container{
                >span{
                    &.com{
                        br{
                            display: none;
                        }
                    }
                }
            }
        }
        .rangeCatalogs{
            .container{
                >span{
                    br{
                        display: none;
                    }
                }
            }
        }
        &.range-pergola-carport{
            .rangeMore{
                .container{
                    .more{
                        div.content{
                            p{
                                br{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .rangeContract{
            .container{
                >div{
                    &.contract{
                        div.text{
                            padding: 0 16px;
                            span.desc{
                                br{
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 500px){
    #range{
        margin-top: 56px;
        .rangeHead{
            width: 100%;
            height: auto;
        }
        .rangeCaption{
            >div:first-child{
                display: none;
            }
            .container{
                width: 100%;
                padding: 0;
                #range-logo{
                    width: 100%;
                    img{
                        height: 150px;
                        width: auto;
                    }
                }
            }
        }
        .rangeDesc{
            div{
                font-size: 16px;
                padding: 0 10px;
                p{
                    width: 100%;
                    padding: 0 10px;
                    display: flex;
                    flex-direction: column;
                    img{
                        width: 100%;
                        height: auto;
                    }
                    br{
                        display: none;
                    }
                }
                >div{
                    width: 100%;
                    padding: 0 10px;
                    line-height: 30px;
                    span:first-child{
                        line-height: 40px;
                        font-size: 20px !important;
                    }
                }
            }
        }
        .rangeEstimateText{
            .container{
                >span{
                    line-height: 25px;
                    &.com{
                        font-weight: normal;
                    }
                }
            }
        }
        .rangeCatalogs{
            .container{
                >span{
                    line-height: 25px;
                }
            }
        }
        .rangeMore{
            padding: 40px 0;
            .container{
                .more{
                    div.content{
                        p{
                            img{
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
        .rangeContract{
            background: none;
            padding: 0;
            .container{
                padding: 0;
                display: flex;
                flex-direction: column;
                >div{
                    width: 100%;
                    padding: 40px 10px;
                    box-sizing: border-box;
                    &.contract{
                        background: #667484;
                    }
                    h3{
                        margin-bottom: 30px;
                    }
                    &.sav{
                        background: #bdcad4;
                        div.text{
                            span{
                                &.desc{
                                    font-size: 13px;
                                }
                            }
                        }
                        h3{
                            margin-bottom: 30px;
                        }
                    }
                    div.text{
                        height: auto;
                        justify-content: space-between;
                        align-items: center;
                        span{
                            font-size: 20px;
                            text-align:center;
                            &.desc{
                                font-size: 17px;
                            }
                            &.point{
                                content: '';
                                width: 10px;
                                height: 10px;
                                background-color: #ffcc00;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
        .rangeBlocs{
            .bloc{
                &:nth-child(even){
                    .container{
                        flex-direction: column;
                    }
                    
                }
                &:nth-child(odd){
                    .container{
                        flex-direction: column-reverse;
                    }
                    
                }
                .container{
                    padding: 0; 
                    display: flex;
                    >div{
                        width: 100%;
                        &.left{
                            padding-right: 0;
                        }
                        &.right{
                            padding-left: 0;
                        }
                        .text{
                            &.alignRight{
                                padding: 0 10px;
                                text-align:center;
                                p{
                                    text-align:center !important;
                                }
                            }
                            &.alignLeft{
                                padding: 0 10px;
                                text-align:center;
                                padding-bottom: 20px;
                                p{
                                    text-align:center !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .rangeImplementations{
            .text{
                height: auto;
                background: #97abbb;
                padding: 10px 0;
                .container{
                    display: flex;
                    >span{
                        color: white;
                        font-size: 20px;
                        text-align:center;
                        width: 100%;
                    }
                }
            }
            .gallery{
                background: #edf1f4;
                .links{
                    padding: 20px 0;
                    .container{
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        align-items: center;
                        row-gap: 10px;
                        .btn-link{
                            display: flex;
                            column-gap: 5px;
                        }
                        .filter-btn{
                            color: #667484;
                            font-size: 15px;
                            &.mixitup-control-active{
                                color: #ffcc00;
                            }
                        }
                    }
                }
                .images{
                    column-gap: 0;
                    row-gap: 0;
                    height: 800px;
                    overflow: hidden;
                    &.expand{
                        height: auto;
                    }
                    >div.mix{
                        width: 50%;
                        height: 200px;
                        max-height: none;
                        position:relative;
                        div.more-hover{
                            position:absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            
                            display: none;
                            cursor: pointer;
                            >svg{
                                color: white;
                                font-size: 40px;
                            }
                            >h2{
                                position:absolute;
                                bottom: 0;
                                width: 100%;
                                text-align:center;
                                text-transform: capitalize;
                                color: white;
                                font-size: 16px;
                                font-weight: lighter;
                            }
                        }
                        &:hover{
                            div.more-hover{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                background: rgba($background-cloture, 0.7);
                            }
                        }
                        img{
                            width: 100%;
                            height: auto;
                            max-height: 310px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}