@font-face {
    font-family: 'Century-Gothic';
    src: url('CenturyGothic.eot');
    src: url('CenturyGothic.eot?#iefix') format('embedded-opentype'),
        url('CenturyGothic.woff2') format('woff2'),
        url('CenturyGothic.woff') format('woff'),
        url('CenturyGothic.ttf') format('truetype'),
        url('CenturyGothic.svg#CenturyGothic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century-Gothic-Bold-Italic';
    src: url('CenturyGothic-BoldItalic.eot');
    src: url('CenturyGothic-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('CenturyGothic-BoldItalic.woff2') format('woff2'),
        url('CenturyGothic-BoldItalic.woff') format('woff'),
        url('CenturyGothic-BoldItalic.ttf') format('truetype'),
        url('CenturyGothic-BoldItalic.svg#CenturyGothic-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bradley-Hand-ITC-TT-Bold';
    src: url('BradleyHandITCTTBold.eot');
    src: url('BradleyHandITCTTBold.eot?#iefix') format('embedded-opentype'),
        url('BradleyHandITCTTBold.woff2') format('woff2'),
        url('BradleyHandITCTTBold.woff') format('woff'),
        url('BradleyHandITCTTBold.ttf') format('truetype'),
        url('BradleyHandITCTTBold.svg#BradleyHandITCTTBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century-Gothic-Italic';
    src: url('CenturyGothic-Italic.eot');
    src: url('CenturyGothic-Italic.eot?#iefix') format('embedded-opentype'),
        url('CenturyGothic-Italic.woff2') format('woff2'),
        url('CenturyGothic-Italic.woff') format('woff'),
        url('CenturyGothic-Italic.ttf') format('truetype'),
        url('CenturyGothic-Italic.svg#CenturyGothic-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Century-Gothic-Bold';
    src: url('CenturyGothic-Bold.eot');
    src: url('CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
        url('CenturyGothic-Bold.woff2') format('woff2'),
        url('CenturyGothic-Bold.woff') format('woff'),
        url('CenturyGothic-Bold.ttf') format('truetype'),
        url('CenturyGothic-Bold.svg#CenturyGothic-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

