@import "../../fonts/stylesheet.css";

.century-gothic{
    font-family: 'Century-Gothic';
}
.century-gothic-bold-italic{
    font-family: 'Century-Gothic';
}
.bradley{
    font-family: 'Bradley-Hand-ITC-TT-Bold';
}
.century-gothic-italic{
    font-family: 'Century-Gothic-Italic';
}
.century-gothic-bold{
    font-family: 'Century-Gothic-Bold';
}