.homeRanges{
    display: flex;
    flex-wrap: wrap;
    overflow:hidden;
    .container{
        display: flex; 
        flex-wrap: wrap;
    }
    .elementRange{
        width: 25%;
        height: 310px;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position:relative;
        cursor: pointer;
        &#portail, &#clôture, &#menuiseries{
            opacity: 0;
            -webkit-transition: all 1.5s ease-in-out;
            -moz-transition: all 1.5s ease-in-out;
            -ms-transition: all 1.5s ease-in-out;
            -o-transition: all 1.5s ease-in-out;
            transition: all 1.5s ease-in-out;
            &.visible{
                opacity: 1;
            }
        }
        &#porte-de-garage, &#pergola-carport, &#déco-et-mobiler-d-extérieur{
            position:relative;
            top: 250px;
            -webkit-transition: all 2s ease-in-out;
            -moz-transition: all 2s ease-in-out;
            -ms-transition: all 2s ease-in-out;
            -o-transition: all 2s ease-in-out;
            transition: all 2s ease-in-out;
            &.visible{
                top: 0
            }
        }
        
        h2{
            font-weight: 200;
            color: white;
            font-size: 25px;
            text-transform: uppercase;
            text-align:center;
        }
        .hover{
            width: 100%;
            height: 100%;
            display: none;
            position:absolute;
            top: 0;
            left: 0;
            
        }
        &.visited{
            .hover{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: rgba(105,117,131, 0.7);
                h2{
                    color: #ffcc00;
                }
                a{
                    font-weight: 200;
                    font-size: 25px;
                    color: #ffcc00;
                    text-transform: uppercase;
                    text-align: center;
                }
            }
        }
        &.childrens{
            h2{
                z-index: 10;
            }
            .subRange{
                display: none;  
            }
            &.visited{
                h2{
                    color: #ffcc00;
                }
                .subRange{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    row-gap: 5px;
                    z-index: 10;
                    a{
                        text-transform: uppercase;
                        color: white;
                        &::first-letter{
                            text-transform: uppercase;
                        }
                        &:hover{
                            color: #ffcc00;
                        }
                    }
                }
                .background-hover{
                    position:absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(105,117,131,0.7);
                    z-index: 9
                }
            }
            
        }
    }
}
@media (max-width: 1024px){
    .homeRanges{
        .elementRange{
            height: 250px;
            opacity: 1 !important;
            top: 0 !important;
            h2{
                font-size: 20px !important;
                text-align:center;
            }
            &.visited{
                .hover{
                    h2{
                        font-size: 18px;
                    }
                    a{
                        font-size: 20px !important;
                    }
                }
            }
            &.childrens{
                &.visited{
                    .subRange{
                        a{
                            font-size: 16px;
                        }
                    }
                }
                
            }
        }
    }
}
@media (max-width: 768px){
    .homeRanges{
        .elementRange{
            height: 200px;
            opacity: 1 !important;
            top: 0 !important;
            h2{
                font-size: 18px !important;
                text-align:center;
            }
            &.visited{
                .hover{
                    h2{
                        font-size: 18px;
                    }
                    a{
                        font-size: 18px;
                    }
                }
            }
            &.module{
                .homeLink{
                    span{
                        justify-content: flex-start;
                        line-height: 30px;
                    }
                }
            }
            &.childrens{
                &.visited{
                    .subRange{
                        a{
                            font-size: 16px;
                        }
                    }
                }
                
            }
        }
    }
}
@media (max-width: 500px){
    .homeRanges{
        margin-top: 20px;
        .elementRange{
            width: 50%;
            opacity: 1 !important;
            top: 0 !important;
            height: 200px;
            &.module{
                width: 50%;
                height: 200px !important;
                .homeLink{
                    justify-content: flex-start;
                    padding:  10px 0;
                    box-sizing: border-box;
                    span{
                        justify-content: flex-start;
                        line-height: 30px;
                    }
                }
            }
            &.visited{
                .hover{
                    a{
                        font-size: 18px !important;
                    }
                }
            }
            &.childrens{
                .subRange{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    row-gap: 5px;
                    z-index: 10;
                    a{
                        text-transform: uppercase;
                        color: white;
                        &::first-letter{
                            text-transform: uppercase;
                        }
                        &:hover{
                            color: #ffcc00;
                        }
                    }
                }
            }
        }
    }
}