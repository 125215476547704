@import 'vars.scss';
#estimate{
    .estimateHead{
        width: 100%;
        height: 70vh;
        background-position:center;
        #textHead{
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1{
                color: white;
                font-weight: normal;
                text-align: center;
                text-transform: uppercase;
                font-size: 60px;
            }
            .contactInfos{
                display: flex;
                justify-content: space-between;
                padding: 60px 0;
                >div{
                    display: flex;
                    column-gap: 15px;
                    width: 30%;
                    justify-content: center;
                    >div{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        span{
                            color: white;
                            font-weight: lighter;
                            &.title{
                                color: #ffcc00;
                                font-size: 20px;
                                height: 30%;
                            }
                            &.text{
                                height: 70%;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    #formEstimate{
        background: #ffcc00;
        .container{
            padding: 60px 0;
            h2{
                text-transform: uppercase;
                color: white;
                padding: 0;
                margin: 0;
                text-align: center;
                font-size: 50px;
                font-weight: normal;
                padding-bottom: 60px;
            }
            .formEstimateText{
                span{
                    text-align:center;
                    width: 100%;
                    display: block;
                    color: white;
                    font-size: 25px;
                }
            }   
            #form{ 
                padding: 60px 0;
                form{
                    display: flex;
                    flex-direction: column;
                    row-gap: 25px;
                    >div:not(.alert){
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap ;
                        row-gap: 25px;
                        &.sel-type{
                            width: 100%;
                            justify-content: center;
                            column-gap: 45px;
                            >div{
                                width: 300px;
                                display: flex;
                                flex-direction: row-reverse;
                                justify-content: center;
                                &:first-child{
                                    justify-content: center;
                                }
                            }
                        }
                        &.textFields{
                            column-gap: 45px; 
                            >div{
                            width: 48%;
                            display: flex;
                            flex-direction: column;
                                input, select{
                                    width: 100%;
                                    border: none;
                                    font-family: 'Century-Gothic';
                                    color: #667484;
                                    font-size: 18px;
                                    padding: 10px 0 10px 10px;
                                }

                            }
                        }
                        &.textareaFields{
                            >div{
                                textarea{
                                    width: 100%;
                                    height: 300px;
                                    resize: none;
                                    border: none;
                                    font-family: 'Century-Gothic';
                                    color: #667484;
                                    font-size: 18px;
                                    padding: 10px;
                                }
                            }
                        }
                        &.subscribeFields{
                            row-gap: 0;
                        }
                        &.submit{
                            justify-content: center;
                            .valid{
                                &:hover{
                                    background: #a8b6c6;
                                    color: white;
                                    border: 2px solid white;
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
    .ranges{
        padding: 50px 0;
        .container{
            color: #bdcad4;
            h4{
                padding-bottom: 50px;
                text-align: center;
                width: 100%;
            }
            .elementRange{
                width: 380px;
            }
        }
    }
    #social{
        height: 65px;
        width: 100%;
        display: block;
        background: #667484;
        .container{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 40px;
            >span{
                color: #a7bac9;
                font-size: 30px;
            }
            a.social{
                width: 44px;
                height: 43px;
                display: block;
                &.social-fb{
                    background: url('../theme/fb.png');
                    background-repeat: no-repeat;
                    &:hover{
                        background: url('../theme/fb-hover.png');
                        background-repeat: no-repeat;
                    }
                }
                &.social-insta{
                    background: url('../theme/insta.png');
                    background-repeat: no-repeat;
                    &:hover{
                        background: url('../theme/insta-hover.png');
                        background-repeat: no-repeat;
                    }
                }
                &.social-linkedin{
                    background: url('../theme/linkedin.png');
                    background-repeat: no-repeat;
                    &:hover{
                        background: url('../theme/linkedin-hover.png');
                        background-repeat: no-repeat;
                    }
                }
                &.social-tiktok{
                    background: url('../theme/tiktok.png');
                    background-repeat: no-repeat;
                    &:hover{
                        background: url('../theme/tiktok-hover.png');
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
    #contactMap{
        width: 100%;
        height: 800px;
        z-index: 0;
    }
}
@media (max-width: 1024px){
    #estimate{
        .estimateHead{
            #textHead{
                .contactInfos{
                    padding: 10px 0;;
                    >div{
                        flex-direction: column;
                        align-items: center;
                        img{
                            height: 105px;
                            width: auto;
                        }
                        >div{
                            margin-top: 10px;
                            span{
                                &.title{
                                    text-align:center;
                                }
                                &.text{
                                    text-align:center;
                                }
                            }
                        }
                    }
                }
            }
        }
        #formEstimate{
            .container{
                #form{
                    form{
                        >div:not(.alert){
                            &.sel-type{
                                column-gap: 30px;
                            }
                            &.textFields{
                                column-gap: 30px; 
                            }
                            
                        }
                    }
                }
            }
        }
        .ranges{
            .container{
                .elementRange{
                    width: 50%;
                }
            }
        }
    }
}
@media (max-width: 768px){
    #estimate{
        margin-top: 56px;
        #formEstimate{
            padding: 0 10px;
            .container{
                #form{
                    form{
                        >div:not(.alert){
                            &.sel-type{
                                column-gap: 29px;
                            }
                            &.textFields{
                                column-gap: 29px; 
                            }
                            
                        }
                    }
                }
            }
        }
        .ranges{
            .container{
                .elementRange{
                    width: 50%;
                }
            }
        }
    }
}
@media (max-width: 500px){
    #estimate{
        margin-top: 56px;
        .estimateHead{
            height: auto;
            padding-bottom: 30px;
            #textHead{
                h1{
                    font-size: 40px;
                }
                .contactInfos{
                    flex-direction: column;
                    align-items: center;
                    row-gap: 50px;
                    >div{
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-around;
                        width: 90%;
                        >div{
                            width: 59%;
                            margin-top: 10px;
                            &.img{
                                display: flex;
                                justify-content: center;
                                width: auto;
                                min-width: 105px;
                                img{
                                    height: 105px;
                                    width: auto;
                                }
                            }
                            span{
                                &.title{
                                    text-align:left;
                                }
                                &.text{
                                    text-align:left;
                                }
                            }
                        }
                        &#telephone{
                            .img{
                                display: flex;
                                justify-content: center;
                                flex-direction: row;
                                img{
                                    width: 78px;
                                }
                            }
                        }
                        &#nous-trouver{
                            .img{
                                display: flex;
                                justify-content: center;
                                flex-direction: row;
                                img{
                                    width: 64px;
                                }
                            }
                        }
                    }
                }

            }
        }
        #formEstimate{
            padding: 0 10px;
            .container{
                padding-top: 80px;
                h2{
                    font-size: 30px;
                }
                .formEstimateText{
                    span{
                            font-size: 20px;
                    }
                }
                #form{
                    form{
                        >div:not(.alert){
                            row-gap: 15px;
                            &.sel-type{
                                column-gap: 0;
                                >div{
                                    width: 50%;
                                    justify-content: flex-start;
                                    &:first-child{
                                        justify-content: flex-end;
                                    }
                                    label{
                                        font-size: 14px;
                                    }
                                }
                            }
                            &.textFields{
                                column-gap: 0; 
                                >div{
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;

                                }
                            }
                            &.subscribeFields{
                                row-gap: 0;
                                label, span{
                                    font-size: 14px;
                                }
                            }
                            &.submit{
                                justify-content: center;
                            }
                            
                        }
                    }
                }
            }
        }
        .ranges{
            .container{
                .elementRange{
                    width: 100%;
                    height: 200px;
                }
            }
        }
        #social{
            height: auto;
            padding: 10px 0;    
            .container{
                gap: 40px;
                row-gap: 5px;
                flex-wrap: wrap;
                >span{
                    color: #a7bac9;
                    font-size: 30px;
                    width: 100%;
                    text-align:center;
                }
                a.social{
                    width: 44px;
                    height: 43px;
                    display: block;
                    &.social-fb{
                        background: url('../theme/fb.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/fb-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                    &.social-insta{
                        background: url('../theme/insta.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/insta-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                    &.social-linkedin{
                        background: url('../theme/linkedin.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/linkedin-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                    &.social-tiktok{
                        background: url('../theme/tiktok.png');
                        background-repeat: no-repeat;
                        &:hover{
                            background: url('../theme/tiktok-hover.png');
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
        #contactMap{
            width: 100%;
            height: 400px;
            z-index: 0;
        }

    }
}
@media (orientation: landscape){
    #estimate{
        box-sizing: border-box;
        padding-top: 118px;
        .estimateHead{
            height: auto;
            box-sizing: border-box;
            background-position: top !important;
            padding-bottom: 40px;
        }
    }
    @media (max-width: 768px){
        #estimate{
            padding-top: 0px;
            margin-top: 70px;
            #formEstimate{
                padding: 0 10px;
                .container{
                    padding-top: 80px;
                    h2{
                        font-size: 30px;
                    }
                    .formEstimateText{
                        span{
                                font-size: 20px;
                        }
                    }
                    #form{
                        form{
                            >div:not(.alert){
                                &.sel-type{
                                    column-gap: 0;
                                    >div{
                                        width: 50%;
                                        justify-content: flex-end;
                                        &:first-child{
                                            justify-content: flex-start;
                                        }
                                        label{
                                            font-size: 14px;
                                        }
                                    }
                                }
                                &.textFields{
                                    flex-direction: column;
                                    >div{
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;

                                    }
                                }
                                &.subscribeFields{
                                    row-gap: 0;
                                    label, span{
                                        font-size: 14px;
                                    }
                                }
                                &.submit{
                                    justify-content: center;
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
}