#legalNotices{ 
    .bloc{
        &:first-child{
            background-color: #edf1f4;
            height: auto;
            padding-top: 150px;
            padding-bottom: 50px;
            h1{
                padding: 0;
                text-transform: uppercase;
                text-align:center;
                color: #004689;
                font-size: 60px;
                padding: 40px 0;
                font-weight: normal;
            }
            .container{
                background: url('/public/build/theme/logo-background.png');
                background-size: 600px;
                background-position :center;
                background-repeat: no-repeat;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                row-gap: 50px;
                padding: 50px 0;
                
                >div{
                    display: flex;
                    flex-direction: column;
                    span{
                        text-align: center;
                        color: #667484;
                        font-size: 20px;
                        margin-bottom: 20px;
                        line-height: 35px;
                        align-self: start;
                        width: 100%;
                        text-align:center;
                        &.title{
                            color: #004689;
                            font-size: 25px;
                            font-weight: 600;
                            margin-bottom: 10px;
                            width: 100%;
                            text-align:center;
                        }
                        a{
                            color: #667484;
                            &:hover{
                                color: black;
                            }
                        }
                        
                    }
                }
            }
        }
        &:nth-child(2){
            background: #d5dde4;
            height: auto;
            padding-bottom: 50px;
            h1{
                padding: 0;
                text-transform: uppercase;
                text-align:center;
                color: #004689;
                font-size: 60px;
                padding: 40px 0;
                font-weight: normal;
            }
            .container{
                background: url('/public/build/theme/logo-background.png');
                background-size: contain;
                background-position :center;
                background-repeat: no-repeat;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                row-gap: 50px;
                padding: 50px 0;
                
                >div{
                    display: flex;
                    flex-direction: column;
                    span{
                        text-align: center;
                        color: #004689;
                        font-size: 20px;
                        margin-bottom: 20px;
                        line-height: 35px;
                        b{
                            font-size: 16px;
                            font-weight: normal;
                        }
                        &.title{
                            color: white;
                            font-size: 25px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                    }
                    &:first-child{
                        span{
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px){
    #legalNotices{
        .bloc{
        padding: 0 10px;
        }
    }
}
@media (max-width: 500px){
    #legalNotices{
        .bloc{
            padding: 0 10px;
            &:first-child{
                padding-top: 100px;
                h1{
                    font-size: 50px;
                }
                .container{
                    background-size: contain;
                    >div{
                        span{
                            font-size: 16px;
                            &.title{
                                font-size: 20px;
                            }
                            
                        }
                    }
                }
            }
            &:nth-child(2){
                h1{
                    font-size: 50px;
                }
                .container{
                    >div{
                        span{
                            font-size: 16px;
                            b{
                                font-size: 14px;
                                font-weight: normal;
                            }
                            &.title{
                                font-size: 20px;
                            }
                        }
                        &:first-child{
                            span{
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}