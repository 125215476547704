.otherRanges{
    #gamme{
        padding: 50px 0;
        h3{
            color: #bac9d5;
            font-size: 45px;
            text-align:center;
            text-transform: uppercase;
            font-weight: normal;
            padding: 50px 0;
        }
        #list{
            display: flex;
            width: 100%;
            height: auto;
            justify-content: space-between;
            >div{
                width: 20%;
                cursor: pointer;
                position:relative;
                height: 190px;
                >span{
                    
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    img{
                        width: 104px;
                        height: auto;
                    }
                    &.hover{
                        display: none;
                        position:absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;

                    }
                }
                &:hover{
                    >span{
                        &.hover{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            align-items: center;
                            a{
                                color: white;
                                text-transform: capitalize;
                                text-align:center;
                                font-size: 16px;
                            }
                        }
                    }
                }
            } 
        }
    }
}
@media (max-width: 500px){
    .otherRanges{
        #gamme{
            padding: 30px 0;
            h3{
                font-size: 30px;
            }
            #list{
                justify-content: space-around;
                row-gap: 30px;
                flex-wrap: wrap;
                >div{
                    width: 40%;
                    cursor: pointer;
                    position:relative;
                    height: 150px;
                    >span{
                        display: none;
                        &.hover{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            align-items: center;
                            a{
                                color: white;
                                text-transform: capitalize;
                                text-align:center;
                                font-size: 16px;
                            }
                        }
                    }
                    &:hover{
                        >span{
                            &.hover{
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                align-items: center;
                                a{
                                    color: white;
                                    text-transform: capitalize;
                                    text-align:center;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                } 
            }
        }
    }
}