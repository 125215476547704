.ranges{
    display: flex;
    flex-wrap: wrap;
    .container{
        display: flex; 
        flex-wrap: wrap;
    }
    .elementRange{
        width: 25%;
        height: 250px;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position:relative;
        cursor: pointer;
        text-align:center;
        h2{
            font-weight: 200;
            color: white;
            font-size: 25px;
            text-transform: uppercase;
            &::first-letter{
                text-transform: uppercase;
            }
        }
        .hover{
            width: 100%;
            height: 100%;
            display: none;
            position:absolute;
            top: 0;
            left: 0;
        }
        &.visited{
            &:not(.childrens){
                h2{
                    display: none;
                }
            }
            .hover{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: rgba(105,117,131, 0.7);
                h2{
                    color: #ffcc00;
                    text-align:center;
                }
                a{
                    font-weight: 200;
                    font-size: 25px;
                    color: #ffcc00;
                    text-transform: uppercase;
                    text-align:center;
                }
            }
        }
        &.childrens{
            h2{
                z-index: 10;
            }
            .subRange{
                display: none;  
            }
            &.visited{
                h2{
                    color: #ffcc00;
                }
                .subRange{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    row-gap: 10px;
                    z-index: 10;
                    a{
                        text-transform: uppercase;
                        color: white;
                        &:hover{
                            color: #ffcc00;
                        }
                    }
                }
                .background-hover{
                    position:absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(105,117,131,0.7);
                    z-index: 9
                }
            }
            
        }
    }
}
@media (max-width: 1024px){
    .homeRanges{
        .elementRange{
            h2{
                font-size: 18px;
            }
            &.visited{
                .hover{
                    h2{
                        text-align:center;
                        font-size: 18px;
                    }
                    a{
                        text-align:center;
                        font-size: 18px !important;
                    }
                }
            }
            &.childrens{
                &.visited{
                    .subRange{
                        a{
                            font-size: 18px;
                        }
                    }
                }
                
            }
        }
    }
}
@media (max-width: 500px){
    .ranges{
        .container{
            row-gap: 10px;
        }
        .elementRange{
            h2{
                text-align:center;
            }
        }
    }
}